import React, { useState, useContext } from 'react';
import { AuthContext } from '../../providers/auth'
import 'bootstrap/dist/css/bootstrap.min.css';
import FillSidebar from '../components/sidebar';
import axios from '../../services/axios';
import ItensPagination from '../components/pagination';
import { Link } from 'react-router-dom';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import ListWaitSales from '../components/orders/list_waiting_sales_orders';
import ListWaitPay from '../components/orders/list_waiting_payment_orders';
import ListDelivered from '../components/orders/list_delivered_orders';
import ListLate from '../components/orders/list_late_orders';
import { Sidebar } from 'primereact/sidebar';
import { GiHamburgerMenu } from 'react-icons/gi';
import { BsArrowUpRight } from 'react-icons/bs';
import { useQuery } from 'react-query';



function ListOrders({ orders }) {

  function formatDate(data) {
    return data.split("-").reverse().join("/");
  }

  function setDate(data) {
    return new Date(data).toLocaleDateString()
  }

  function setStatus(status) {
    switch (status) {
      case 'AWAITING_PAYMENT':
        return <span className="badge badge-pill badge-warning mt-2">Aguardando pagamento</span>
      case 'AWAITING_DELIVERY':
        return <span className="badge badge-pill badge-warning mt-2">Aguardando Entrega</span>
      case 'DELIVERED':
        return <span className="badge badge-pill badge-success mt-2">Entregue</span>
      case 'OUT_FOR_DELIVERY':
        return <span className="badge badge-pill badge-success mt-2">Saiu para Entrega</span>
      case 'LATE_ORDER':
        return <span className="badge badge-pill badge-danger mt-2">Pedido Atrasado</span>
      case 'ORDER_CANCELED':
        return <span className="badge badge-pill badge-danger mt-2">Cancelado</span>
      case 'WAITING_FOR_APPROVAL':
        return <span className="badge badge-pill badge-warning mt-2">Aguardando aprovação</span>
      case 'WAITING_FOR_AUCTION':
        return <span className="badge badge-pill badge-warning mt-2">Aguardando Leilão</span>
      case 'IN_QUOTATION':
        return <span className="badge badge-pill badge-warning mt-2">Leilão em andamento</span>
      case 'ACTIVE':
        return <span className="badge badge-pill badge-warning mt-2">Em Leilão</span>
      default:
        return <span className="badge badge-pill badge-secondary mt-2">Não identificado</span>
    }
  }

  if (!orders.length) {
    return <p>Voce não possui nenhum pedido</p>
  }

  return (
    <>
      <div className='row orders-table pt-5'>
        <div className='col-12'>
          <table className="table user-list">
            <thead>
              <tr className='text-center'>
                <th><span>Pedido Num</span></th>
                <th><span>Categoria</span></th>
                <th><span>Tipo do produto</span></th>
                <th><span>Criado por</span></th>
                <th><span>Pedido</span></th>
                <th><span>Quantidade</span></th>
                <th><span>Prazo de entrega</span></th>
                <th><span>Status</span></th>
                <th><span>Lote</span></th>
              </tr>
            </thead>
            <tbody>
              {orders.map(function (order, key) {
                return (
                  <tr key={key} className='pt-3 text-center'>
                    <td>
                      <span>{order.num_order}</span>
                    </td>
                    <td>
                      {order.group}
                    </td>
                    <td>
                      {order.label}
                    </td>
                    <td>
                      {order.first_name} {order.last_name}
                    </td>
                    <td className="text-center">
                      {setDate(order.created_at)}
                    </td>
                    <td className='text-center'>
                      {order.quantity}
                    </td>
                    <td className='text-center'>
                      {formatDate(order.deadline)}
                    </td>
                    <td>
                      {setStatus(order.status)}
                    </td>
                    <td>
                    {
                        ['AWAITING_PAYMENT', 'AWAITING_DELIVERY', 'OUT_FOR_DELIVERY', 'LATE_ORDER'].includes(order.status) ?
                        <Link to={`/payments/orders/${order.purchaser_id}?lote=${order.sale_date}`}><BsArrowUpRight size={16} /></Link>
                        :
                        <></>
                      }
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
      {orders.map(function (order) {
        return (
          <div className='row orders-cards'>
            <div className='col-12'>
              <div className="card border shadow-lg my-3">
                <div className="card-body">
                  <div className='row py-3'>
                    <div className='col-4'>
                      <p className='text text-white h3'>{order.num_order}</p>
                    </div>
                    <div className='col-8 text-right'>
                      {setStatus(order.status)}
                    </div>
                  </div>
                  <div className='row py-3 text-center'>
                    <div className='row'>
                      <div className='col-6'>
                        <small>
                          <p className='p-0 m-0'><strong>Criado em: </strong></p>
                          <p className="p-0 m-0">{order.first_name} {order.last_name}</p>
                        </small>
                      </div>
                      <div className='col-6'>
                        <small>
                          <p className='p-0 m-0'><strong>Pedido: </strong></p>
                          <p className="p-0 m-0"><strong>{order.group}</strong> {order.label}</p>
                        </small>
                      </div>
                    </div>
                  </div>
                  <div className='row text-center'>
                    <div className='col-6'>
                      <small>
                        <p className='p-0 m-0'><strong>Quantidade: </strong></p>
                        <p className="p-0 m-0">{order.quantity}</p>
                      </small>
                    </div>
                    <div className='col-6'>
                      <small>
                        <p className='p-0 m-0'><strong>Prazo de Entrega: </strong></p>
                        <p className='p-0 m-0'>{formatDate(order.deadline)}</p>
                      </small>
                    </div>
                  </div>
                  <div className='row pt-2'>
                    <div className='col-12 text-center'>
                      {
                        ['AWAITING_PAYMENT', 'AWAITING_DELIVERY', 'OUT_FOR_DELIVERY', 'LATE_ORDER'].includes(order.status) ?
                        <Link to={`/payments/orders/${order.purchaser_id}?lote=${order.sale_date}`} className='btn btn-green'>Ver Lotes <BsArrowUpRight size={16} /></Link>
                        :
                        <></>
                      }
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      })}
    </>
  )
}


function MyOrders() {
  const [orderPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const { user } = useContext(AuthContext);
  const [visibleLeft, setVisibleLeft] = useState(false);

  const { data, isLoading: usersLoading, isError: usersError } = useQuery(['getAllOrders', currentPage], () =>
    axios
      .get(`/api/v1/orders/company/${user.companyId}?page=${currentPage}&limit=${orderPerPage}`)
      .then(response => response.data)
  );

  if (usersLoading) {
    return <div>Loading...</div>;
  }

  if (usersError) {
    return <div>Error fetching data</div>;
  }

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className='container-fluid'>
      <div className="row">
        <div className='col-md-3 col-lg-2 col-2 p-0'>
          <div className="card menubtn">
            <button onClick={() => setVisibleLeft(true)} className="btn btn-outline text-center" >
              <GiHamburgerMenu size={26} />
            </button>
            <Sidebar visible={visibleLeft} onHide={() => setVisibleLeft(false)}>
              <FillSidebar user={user} />
            </Sidebar>
          </div>

          <div className='side-list' >
            <FillSidebar user={user} />
          </div>
        </div>
        <div className='col-md-8 offset-md-1 col-lg-8 col-xl-10 offset-xl-0 offset-lg-2 pl-xl-4 pl-md-4 pl-lg-0 pl-xl-4 pr-md-5 pt-4'>
          <div className='row mb-5'>
            <div className='col-12'>
              <div className='row pt-3'>
                <div className='col-12'>
                  <p className='h2'>Bem vindo, {user.firstName}</p>
                  <p className=''>
                    Na seção de detalhes do pedido, você pode revisar e gerenciar todos os pedidos com seus
                    detalhes. Você pode visualizar e editar muitas informações,<br /> como IDs de todos os pedidos,
                    produtos solicitados, data do pedido, preço e status do pedido. O acesso a esta área é limitado.
                    Somente administradores <br />e líderes de equipe podem alcançar. As alterações feitas serão
                    aprovadas após serem verificadas
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-12 d-flex justify-content-end'>
              <Link to="/add/order" className="btn btn-green"><strong>Criar pedido</strong></Link>
            </div>
          </div>
          <div className='row'>
            <div className='col-12'>
              <Tabs
                defaultActiveKey="all"
                id="uncontrolled-tab-example"
                className="mb-2 border-0 border-bottom flex-column flex-sm-row"
              >
                <Tab eventKey="all" title="Todos">
                  <ListOrders orders={data.orders} />
                  <ItensPagination ordersPerPage={orderPerPage} totalOrders={data.total} paginate={paginate} currentPage={currentPage} />
                </Tab>
                <Tab eventKey="aguardando_leil" title="Aguardando leilão">
                  <ListWaitSales />
                </Tab>
                <Tab eventKey="aguardando_aprov" title="Aguardando pagamento">
                  <ListWaitPay />
                </Tab>
                <Tab eventKey="atrasado" title="Atrasado">
                  <ListLate />
                </Tab>
                <Tab eventKey="entregue" title="Entregue">
                  <ListDelivered />
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MyOrders;