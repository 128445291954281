import React, { createContext, useState } from 'react';

export const NotifyContext = createContext();

export const NotifyProvider = ({ children }) => {
  const [currentPage, setCurrentPage] = useState(1);

  return (
    <NotifyContext.Provider value={{ currentPage, setCurrentPage }}>
      {children}
    </NotifyContext.Provider>
  );
};
