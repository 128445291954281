import React, { useState, useContext, useEffect } from 'react';
import { AuthContext } from '../../providers/auth'
import { useNavigate, Link } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css';
import Sidebar from '../components/sidebar'
import '../../utils/css/global.css'
import axios from '../../services/axios';
import Select from 'react-select';
import { Modal, CloseButton } from "react-bootstrap";
import Ok from '../../assets/status_ok.svg';
import { MdError } from 'react-icons/md';
import { v4 as uuidv4 } from 'uuid';
import { getNextThursday } from '../../utils/js/timeToSale';
import OrderTable from './orders_table_component';
import { toast } from 'react-toastify';


function SuccessfulCreateOrder(props) {
  const navigate = useNavigate()
  const { message, hasError } = props.content

  function closeModal() {
    navigate('/');
  }

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div className='row'>
          <div className='col-12 text-right'>
            <div className=''>
              <CloseButton onClick={closeModal}></CloseButton>
            </div>
          </div>
        </div>
        <div className='container text-center'>
          <div className='row'>
            <div className='col-12'>
              {hasError ?
                <MdError size={30} />
                : <img alt='okicon' width='150px' height='150px' src={Ok} />
              }
            </div>
          </div>
          <div className='row my-3 text-center'>
            <div className='col-12'>
              {hasError ?
                <p>{message}</p>
                : <p>Sua solicitação foi criada e entrará em leilão dia {new Date(message).toLocaleDateString()}, os fornecedores do segmento escolhido ja receberam sua demanda!</p>
              }
            </div>
          </div>
          <div className='row'>
            <div className='col-12 text-center'>
              <Link className="nav-link link-dark text-decoration-none" to="/orders"><strong>Meus pedidos</strong></Link>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}


function CreateOrder() {
  const { user } = useContext(AuthContext)

  const pagamentos = [
    { value: 'bank_ticket', label: 'Boleto Bancario a vista', name: 'paymentMethod' },
    { value: 'bank_ticket_10_days', label: 'Boleto Bancario com 10 dias de vencimento', name: 'paymentMethod' },
  ]
  const [products, setProducts] = useState([]);
  const [categoties, setCategoties] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [ordersToCreate, setOrdersToCreate] = useState([]);
  const [content, setContent] = useState({
    message: '',
    hasError: false
  });

  const [data, setData] = useState({
    companyId: user.companyId,
    userId: user.id,
    announcement_date: new Date().toISOString().split('T')[0],
    measurement: 'KG',
    paymentMethod: pagamentos[0]
  });

  useEffect(() => {
    getCategories()
  }, [])

  useEffect(() => {
    if (!data?.category?.value) return
    getProducts(data?.category?.value)
    setData({
      ...data,
      product: '',
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.category])

  async function getProducts(categoryId) {
    if (!categoryId) return
    await axios.get(`/api/v1/products/types/${categoryId}`).then(response => {
      setProducts(response.data)
    }).catch((error) => {
      toast.error(error.message)
    })

  }

  async function getCategories() {
   await axios.get(`/api/v1/products/categories`).then(response => {
      setCategoties(response.data)
    }).catch((error) => {
      toast.error(error.message)
    })
  }

  function handleChange(event) {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    })
  }

  function handleChangeEnum(event) {
    setData({
      ...data,
      [event.name]: event,
    })
  }


  function addInTable(e) {
    e.preventDefault()
    setOrdersToCreate(orders => [...orders, {
      id: uuidv4(),
      companyId: user.companyId,
      userId: user.id,
      // announcement_date: new Date().toISOString().split('T')[0],
      measurement: 'KG',
      // number: (ordersToCreate.length > 0) ? ordersToCreate.length + 1 : 1,
      quantity: data.quantity,
      category: data.category,
      product: data.product,
      deadline: getNextThursday()?.toISOString()?.split('T')[0],
      paymentMethod: data.paymentMethod
    }])
  }



  // function removeItem() {
  //   setOrdersToCreate((ordersToCreate) => (ordersToCreate.slice(0, -1)));
  // }

  function sendRequest() {
    axios.post('/api/v1/orders', ordersToCreate).then(({ data }) => {
      setContent({
        message: data.message,
        hasError: false
      })
      setModalShow(true)
    }).catch(({ message }) => {
      setContent({
        message,
        hasError: true
      })
      setModalShow(true)
    })
  }

  return (
    <div className="container-fluid ">
      <div>
        <div className='row'>
          <div className='col-2 p-0'>
            <Sidebar user={user} />
          </div>
          <div className='col-10 px-5 mb-5'>
            <h1 className='text-center pt-5'><strong>Cadastro de produto</strong></h1>
            <ul className="mx-3">
              <li className="form-text text-muted">
                <small>
                  - Os pedidos tem o prazo maximo de entrega na quinta-feira.
                </small>
              </li>
              <li className="form-text text-muted">
                <small>
                  - O leilão ocorre toda segunda-feira das 12:00 as 22:00.
                </small>
              </li>
            </ul>
            <form className='form-group mx-5' onSubmit={addInTable}>
              <div className='row d-flex justify-content-center py-2'>
                <div className='col-md-6'>
                  <div className="form-group required">
                    <label className='control-label'><strong>Categoria:</strong></label>
                    <Select className='rounded' placeholder='' options={categoties} name='category'
                      onChange={event => handleChangeEnum({ name: 'category', ...event })}
                    />
                  </div>
                </div>
                <div className='col-md-6'>
                  <div className="form-group required">
                    <label className='control-label'><strong>Tipo do Produto:</strong></label>
                    <Select className="rounded" placeholder='' options={products} name="product"
                      value={products.find(product => product?.value === data?.product?.value) || ''}
                      onChange={event => handleChangeEnum({ name: 'product', ...event })}
                    />
                  </div>
                </div>
              </div>
              <div className='row d-flex justify-content-center py-2'>
                <div className='col-md-6'>
                  <div className="form-group required">
                    <label className='control-label'><strong>Quantidade:</strong></label>
                    <input type="number" className="form-control rounded mr-3" id="quantity" name="quantity"
                      placeholder="Quantidade (em Kg)" onChange={handleChange} />
                  </div>
                </div>
                <div className='col-md-6'>
                  <div className="form-group required">
                    <label className='control-label'><strong>Forma de pagamento:</strong></label>
                    <Select className='rounded' isDisabled={user.ordersDelivering < 10} options={pagamentos} defaultValue={pagamentos[0]} name='paymentMethod' onChange={handleChangeEnum} />
                  </div>
                </div>
              </div>
              <div className='row pt-3'>
                <div className='col-6'>
                  <Link className='btn btn-danger text-white text-decoration-none' to='/'>Voltar</Link>
                </div>
                <div className='col-6 d-flex justify-content-end'>
                  <button type='submit' className='btn btn-green'>Adicionar na lista</button>
                </div>
              </div>
            </form>
            <div className='row pt-5'>
              <div className='col'>
                <table className="table user-list">
                  <thead>
                    <tr className='text-center'>
                      <th><span>#</span></th>
                      <th><span>Categoria</span></th>
                      <th><span>Tipo do Produto</span></th>
                      <th><span>Quantidade</span></th>
                      <th><span>Forma de Pagamento</span></th>
                      <th>&nbsp;</th>
                    </tr>
                  </thead>
                  <tbody className='text-center'>
                    {
                      ordersToCreate ?
                        <OrderTable orderData={ordersToCreate} setOrdersToCreate={setOrdersToCreate} />
                        :
                        <>Crie um pedido para aparecer na listagem</>
                    }
                  </tbody>
                </table>
              </div>
            </div>
            <div className='row'>
              <div className='col-6 d-flex justify-content-start'>
                <button className='btn btn-green'>Remover</button>
                {/* <button onClick={() => removeItem()} type='submit' className='btn btn-green'>Remover</button> */}
                {/* <td><button onClick={() => sendRequest(order.id, 'declined')} className='btn p-0 text-center'><img alt='trashicon' src={Trash} width='20px' height='20px' /></button></td> */}
              </div>
              <div className='col-6 d-flex justify-content-end'>
                <button onClick={() => sendRequest()} type='submit' className='btn btn-green'>Cadastrar</button>
              </div>
            </div>
          </div>
        </div>
        <SuccessfulCreateOrder
          show={modalShow}
          content={content}
          onHide={() => setModalShow(false)}
        />
      </div>
    </div>
  );
}

export default CreateOrder;
