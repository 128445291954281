import React, { useState, useContext } from 'react';
import { AuthContext } from '../../providers/auth'
import { Link } from 'react-router-dom';
import Eye from '../../assets/eye-black.svg'
import Alert from 'react-bootstrap/Alert';

import 'bootstrap/dist/css/bootstrap.min.css';
import '../../utils/css/global.css'

function App() {
    const { setClient } = useContext(AuthContext)
    const [data, setData] = useState({});
    const [alerta, setAlerta] = useState();

    function handleChange(event) {
        setData({
            ...data,
            [event.target.name]: event.target.value,
        })
    }

    function login(e) {
        e.preventDefault()
        const formData = new FormData(e.target);
        const formProps = Object.fromEntries(formData);
        if (formProps.email === '') {
            setAlerta('Por favor, informe um email')
        } else if (formProps.pass === '') {
            setAlerta('Por favor, informe uma senha')
        }
        const teste = async () => {
            const a = await setClient(data)
            setAlerta(a)
        }
        teste()
        

    }

    return (
        <div className='container-fluid login-background'>
            <div className="container">
                <div className='row pt-5 item d-flex justify-content-center align-items-center'>
                    <div className='col-md-6 col-sm-12 offset-1'>
                        <div className="row pt-4">
                            <div className="col-12">
                                <p className=' h1 text-center'><strong>Fillstock</strong></p>
                                <p className='text-center'>Why do we use it?
                                    It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.
                                    The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here,
                                    content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum 
                                    as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions
                                    have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-4 offset-md-1 col-sm-12'>
                        <div className='row'>
                            <div className='col-12 border shadow text-center'>
                                <div className='row pt-3'>
                                    <div id='alert' className='col-12'>
                                        {alerta && 
                                            <Alert className='font-weight-bold' key='warning' variant='warning'>{alerta}</Alert>
                                        }
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-10 offset-1'>
                                        <p className='h3 pt-3'><strong>Entrar</strong></p>
                                        <p className=''>
                                            Se você não possui uma conta, entre em contato com a gente por email.
                                        </p>
                                        <form onSubmit={login} className='form-group'>
                                            <div className='form-group required text-left'>
                                                <label htmlFor="id_username" className='control-label text-green'><strong>Email:</strong></label>
                                                <input type="email" className="textinput textInput form-control" id="id_username" name="email"
                                                    placeholder="Digite seu email" onChange={handleChange} />
                                            </div>
                                            <div className='form-group required text-left'>
                                                <label htmlFor="id_password" className='control-label text-green'><strong>Senha:</strong></label>
                                                <div className='input-group'>
                                                    <input type="password" className="textinput textInput form-control" id="id_password" name="pass"
                                                        placeholder="Digite sua senha" onChange={handleChange} />
                                                    <div className="input-group-append">
                                                        <button className="btn btn-green" type="button" id="id_eye2">
                                                            <img alt='olhoicon' id="olho" src={Eye} />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <p className='text-left'><Link to='#' className='link-dark text-decoration-none'><strong>Esqueceu a senha?</strong></Link></p>
                                            <button type="submit" className='btn btn-green btn-block px-5'>
                                                <span className='text-white'><strong>ENTRAR</strong></span>
                                            </button>
                                        </form>
                                        <p className='text-center'><Link to='/sign-up' className='p-0 link-dark text-decoration-none'><strong>Não tem uma conta?</strong></Link></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    );
}

export default App;