import React, { useContext, useState, useEffect } from 'react';
import { AuthContext } from '../../providers/auth'
import { FiUser, FiUsers } from 'react-icons/fi';
import { AiOutlineHome } from 'react-icons/ai'
import { BsCart, BsCardChecklist } from 'react-icons/bs';
import { MdOutlineAttachMoney } from 'react-icons/md'
import { RiAuctionLine } from 'react-icons/ri';
import { IoMdNotificationsOutline } from 'react-icons/io';
import { Link } from 'react-router-dom';
import RatingModal from './rating/rating-modal';
import { toast } from 'react-toastify';

import Logo from '../../assets/fillstock-logo.svg';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from '../../services/axios';

function FillSidebar() {
    const { user, logout } = useContext(AuthContext)
    const [modalShow, setModalShow] = useState(null);
    const [data, setData] = useState()


    useEffect(() => {
        getNotReadNotify();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    function firstChar(name) {
        return name.substring(0, 1)
    }

    async function getNotReadNotify() {
        if (user.id) {
            await axios.get(`/api/v1/notifications/sidebar/${user.id}`).then(response => {
                setData(response.data.notifications[0].count)
            }).catch((error) => {
                toast.error(error.message)
            })
        }
    }

    if (user.kind === "PROVIDER") {
        let style = document.createElement('style');
        style.innerHTML = `
        .p-sidebar {
            --bs-bg-opacity: 1;
            background-color: rgba(var(--bs-dark-rgb),var(--bs-bg-opacity));
        }
        `;
        document.head.appendChild(style);
    } else {
        //document.getElementsByClassName('p-sidebar')[0].style.backgroundColor = "#f8f9fa";
        let style = document.createElement('style');
        style.innerHTML = `
        .p-sidebar {
            background-color: #f8f9fa;
        }
        `;
        document.head.appendChild(style);
    }

    return (

        <div className={`d-flex flex-column flex-shrink-0 p-3 sidebar ${(user.kind === "PROVIDER") ? "bg-dark" : "bg-light"}`}>
            <div className="dropdown">
                <div className='row pt-3'>
                    <div className='col-3 p-0 mr-5 ml-3'>
                        <small>
                            <button onClick={() => { setModalShow(true) }} className='btn btn-link text-decoration-none'>
                                <span className="btn-circle btn-lg" data-tooltip="tooltip" data-placement="auto" title={user.companyScore}>
                                    {firstChar(user.firstName)}
                                </span>
                                <span className="badge badge-pill badge-success rating">

                                    {(Math.round(user.companyScore * 100) / 100).toFixed(2)}
                                </span>
                            </button>
                        </small>
                        <RatingModal
                            show={modalShow}
                            onHide={() => setModalShow(false)}
                        />
                    </div>
                    <div className='col-6 p-0 pt-2 text-left'>
                        <span
                            className={`text-decoration-none dropdown-toggle ${(user.kind === "PROVIDER") ? "text-white" : "text-dark"}`}
                            id="dropdownUser1"
                            data-bs-toggle="dropdown"
                            aria-expanded="false">
                            <strong>{user.firstName}</strong>
                        </span>
                        <ul className={`dropdown-menu text-small shadow ${(user.kind === "PROVIDER") ? "dropdown-menu-dark" : "dropdown-menu-light"}`} aria-labelledby="dropdownUser1">
                            <li><a className="dropdown-item" href="/orders">Meus pedidos</a></li>
                            <li><a className="dropdown-item" href="/my-data">Meus dados</a></li>
                            <li><hr className="dropdown-divider" /></li>
                            <li><span className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => logout()}>Sair</span></li>
                        </ul>
                    </div>
                </div>
            </div>
            <hr className='bg-white' />
            <ul className="nav nav-pills flex-column mb-auto">
                <li className="nav-item">
                    <Link to="/" className={`nav-link ${(user.kind === "PROVIDER") ? "text-white" : "text-dark"} ${window.location.pathname === '/' && 'active'}`}>
                        <AiOutlineHome className="mr-2" size={24} />
                        Home
                    </Link>
                </li>
                {
                    (user.role === 'ADMIN') || (user.role === 'MASTER') ?
                        (<>
                            <li className="nav-item">
                                <Link to="/approve" className={`nav-link ${(user.kind === "PROVIDER") ? "text-white" : "text-dark"} ${window.location.pathname === '/approve' && 'active'}`}>
                                    <BsCardChecklist className="mr-2" size={24} />
                                    Aprovar pedidos
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/users" className={`nav-link ${(user.kind === "PROVIDER") ? "text-white" : "text-dark"} ${window.location.pathname === '/users' && 'active'}`}>
                                    <FiUsers className="mr-2" size={24} />
                                    Usuarios
                                </Link>
                            </li>
                        </>)
                        :
                        <></>
                }
                {user.kind === "PROVIDER" ?
                    <li className="nav-item">
                        <Link to="/list" className={`nav-link ${(user.kind === "PROVIDER") ? "text-white" : "text-dark"} ${window.location.pathname === '/list' && 'active'}`}>
                            <RiAuctionLine className="mr-2" size={24} />
                            Leilão
                        </Link>
                    </li>
                    :
                    <></>
                }
                <li className="nav-item">
                    {user.kind === "CLIENT" ?
                        <Link to="/payments" className={`nav-link ${(user.kind === "PROVIDER") ? "text-white" : "text-dark"} ${window.location.pathname.split('/').slice(1)[0] === 'payments' && 'active'}`}>
                            <MdOutlineAttachMoney className="mr-2" size={24} />
                            Pagamentos
                        </Link>
                        :
                        <></>
                    }
                </li>
                <li className="nav-item">
                    <Link to="/my-data" className={`nav-link ${(user.kind === "PROVIDER") ? "text-white" : "text-dark"} ${window.location.pathname === '/my-data' && 'active'}`}>
                        <FiUser className="mr-2" size={24} />
                        Meus Dados
                    </Link>
                </li>
                <li className="nav-item">
                    {user.kind === "PROVIDER" ?
                        <Link to="/sales" className={`nav-link ${(user.kind === "PROVIDER") ? "text-white" : "text-dark"} ${window.location.pathname.split('/').slice(1)[0] === 'sales' && 'active'} ${window.location.pathname.split('/').slice(1)[0] === 'lots' && 'active'}`}>
                            <BsCart className="mr-2" size={24} />
                            Meus Leilões
                        </Link>
                        :
                        <Link to="/orders" className={`nav-link ${(user.kind === "PROVIDER") ? "text-white" : "text-dark"} ${window.location.pathname === '/orders' && 'active'}`}>
                            <BsCart className="mr-2" size={24} />
                            Meus Pedidos
                        </Link>
                    }

                </li>
                <li className="nav-item">
                    <Link to="/notify" className={`nav-link ${(user.kind === "PROVIDER") ? "text-white" : "text-dark"} ${window.location.pathname === '/notify' && 'active'}`}>
                        <IoMdNotificationsOutline className="mr-2" size={24} />
                        Notificações <span className='float-right'><span className="badge badge-primary badge-pill">{data}</span></span>
                    </Link>
                </li>
            </ul>
            <a href="/" className="pl-5 d-flex">
                <img className="img-fluid" alt='logoicon' width="200" height="200" src={Logo} />
            </a>
        </div>
    );
}

export default FillSidebar;