import React, { useEffect, useState, useContext } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../../utils/css/global.css'
import { Modal, Card } from "react-bootstrap";
import { FaStar } from "react-icons/fa";
import { AuthContext } from '../../../providers/auth';
import ItensPagination from '../pagination';
import { toast } from 'react-toastify';
import axios from '../../../services/axios';

export default function RatingModal(props) {
    const { user } = useContext(AuthContext)
    const [orderPerPage] = useState(3);
    const [currentPage, setCurrentPage] = useState(1)
    const [data, setData] = useState({
        ratings: [],
        total: 1
    })

    useEffect(() => {
        getOrders();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage])

    async function getOrders() {
        if (user.companyId) {
            await axios.get(`/api/v1/ratings/company/${user.companyId}?page=${currentPage}&limit=${orderPerPage}`).then(response => {
                setData(response.data)
            }).catch((error) => {
                toast.error(error.message)
            })
        }
    }

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    function setStatus(status) {
        switch (status) {
          case 'AWAITING_PAYMENT':
            return <span className="badge badge-pill badge-warning mt-2">Aguardando pagamento</span>
          case 'AWAITING_DELIVERY':
            return <span className="badge badge-pill badge-warning mt-2">Aguardando Entrega</span>
          case 'DELIVERED':
            return <span className="badge badge-pill badge-success mt-2">Entregue</span>
          case 'OUT_FOR_DELIVERY':
            return <span className="badge badge-pill badge-success mt-2">Saiu para Entrega</span>
          case 'LATE_ORDER':
            return <span className="badge badge-pill badge-danger mt-2">Pedido Atrasado</span>
          case 'ORDER_CANCELED':
            return <span className="badge badge-pill badge-danger mt-2">Cancelado</span>
          case 'WAITING_FOR_APPROVAL':
            return <span className="badge badge-pill badge-warning mt-2">Aguardando aprovação</span>
          case 'WAITING_FOR_AUCTION':
            return <span className="badge badge-pill badge-warning mt-2">Aguardando Leilão</span>
          case 'IN_QUOTATION':
            return <span className="badge badge-pill badge-warning mt-2">Leilão em andamento</span>
          default:
            return <span className="badge badge-pill badge-secondary mt-2">Não identificado</span>
        }
      }

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    <h3 className="mb-3">Suas avaliações</h3>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div id="carouselExampleIndicators2" className="carousel slide" data-ride="carousel">
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <div className='row'>
                                {
                                    data?.total > 0 ?
                                        data.ratings.map(rating => (
                                            <div className='col-lg-4 col-md-6 pt-2'>
                                                <Card>
                                                    <Card.Body>
                                                        <Card.Title className='text-center h3'>Pedido Numero: {rating.num_order}</Card.Title>
                                                        <Card.Text>
                                                            <div className='row py-2'>
                                                                <div className='col-5 text-center'>
                                                                    <small>
                                                                        <p className='p-0 m-0'><strong>Criado em:</strong></p>
                                                                        <p className='p-0 m-0'>{new Date(rating.created_at).toLocaleDateString()}</p>
                                                                    </small>
                                                                </div>
                                                                <div className='col-7 text-center'>
                                                                    <small>
                                                                        <p className='p-0 m-0'><strong>Status do pedido:</strong></p>
                                                                        {setStatus(rating.status)}
                                                                    </small>
                                                                </div>
                                                            </div>
                                                        </Card.Text>
                                                        <Card.Text className='text-center'>
                                                            {[...Array(5)].map((item, index) => {
                                                                return (
                                                                    <label>
                                                                        <div style={{ "cursor": "pointer" }}>
                                                                            <FaStar
                                                                                color={
                                                                                    (index + 1) <= rating.score
                                                                                        ? "000"
                                                                                        : "rgb(192,192,192)"
                                                                                }
                                                                            />
                                                                        </div>
                                                                    </label>
                                                                );
                                                            })}
                                                        </Card.Text>
                                                        <Card.Text>
                                                            <div className="dialogbox">
                                                                <div className="body">
                                                                    <span className="tip tip-up"></span>
                                                                    <div className="message">
                                                                        <span>{rating.comments}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Card.Text>
                                                    </Card.Body>
                                                </Card>
                                            </div>
                                        ))
                                        :
                                        <div className="row">
                                            <div className="col-12 text-center pt-3">
                                                <h1> Você não possuí avaliações</h1>
                                            </div>
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                {
                    data &&
                    <ItensPagination ordersPerPage={orderPerPage} totalOrders={data.total} paginate={paginate} currentPage={currentPage} />
                }
            </Modal.Body>
        </Modal>
    );
}
