import React, { useState, useContext } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../utils/css/global.css';
import { Modal } from "react-bootstrap";
import { useMutation } from 'react-query';
import axios from '../../services/axios';
import { AuthContext } from '../../providers/auth';
import { toast } from 'react-toastify';

export default function Lance(props) {
    const { user } = useContext(AuthContext);
    const [valor, setValor] = useState(0);

    const makeLanceMutation = useMutation(({ orderId, purchaserId, lastOffer }) => {
        let floatResult = Number(lastOffer).toFixed(2);
        if (!floatResult) {
            throw new Error('O valor informado deve ser maior que 0');
        }
        return axios.put(`/api/v1/sales/offer/${orderId}`, {
            purchaserId,
            lastOffer: floatResult
        });
    });

    const handleSubmit = async (orderId) => {
        await makeLanceMutation.mutateAsync({
            orderId,
            purchaserId: user.companyId,
            lastOffer: valor
        }).then(() => {
            toast.success("Lance efetuado!")
        }).catch((error) => {
            toast.error(error.response?.data?.error || error.message)
        })
        props.reloadList(props.params());
        props.onHide();
    };

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {props.orderdetails?.label}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='container'>
                    <div className='row d-flex justify-content-center'>
                        <div className='col-6'>
                            <div className='form-group'>
                                <p className='control-label font-weight-bold'>Quantidade do pedido (KG):</p>
                                <p className='control-label font-weight-bold'>Valor do lance atual:</p>
                                <p className='control-label font-weight-bold'>Seu Lance (Valor por KG):</p>
                            </div>
                        </div>
                        <div className='col-6'>
                            <div className='form-group'>
                                <p className='control-label'>{props.orderdetails?.quantity}</p>
                                <p className='control-label'>
                                    {props.orderdetails?.lastOffer
                                        ? props.orderdetails?.lastOffer
                                        : props.orderdetails?.initialOffer
                                            ? props.orderdetails?.initialOffer
                                            : 'Não possui nenhum lance'}
                                </p>
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1">R$</span>
                                    </div>
                                    <input
                                        type='number'
                                        step='0.01'
                                        min='1'
                                        className='form-control'
                                        value={valor}
                                        onChange={(evt) => setValor(evt.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row pt-4 pb-2'>
                        <div className='col-6 d-flex justify-content-start'>
                            <button onClick={props.onHide} className="btn btn-outline-success">Fechar</button>
                        </div>
                        <div className='col-6 d-flex justify-content-end'>
                            <button onClick={() => handleSubmit(props.orderdetails?.id)} className="btn btn-success">Confirmar Lance</button>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}
