import React, { useState, useContext, useEffect } from 'react';
import { AuthContext } from '../../providers/auth';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../utils/css/global.css'
import { Modal } from "react-bootstrap";
import Select from 'react-select';
import { toast } from 'react-toastify';
import axios from '../../services/axios';


export default function EditOrder(props, item) {
    const { user } = useContext(AuthContext)

    const pagamentos = [
      { value: 'bank_ticket', label: 'Boleto Bancario a vista', name: 'paymentMethod' },
      { value: 'bank_ticket_10_days', label: 'Boleto Bancario com 10 dias de vencimento', name: 'paymentMethod' },
    ]
    const [products, setProducts] = useState([]);
    const [categoties, setCategoties] = useState(false);
  
    const [data, setData] = useState({
      orderId: props.item.id,
      product: props.item.product,
      category: props.item.category,
      quantity: props.item.quantity
    });
  
    useEffect(() => {
      getCategories()
    }, [])
  
    useEffect(() => {
      if (!data?.category?.value) return
      getProducts(data?.category?.value)
      setData({
        ...data,
        product: '',
      })
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data.category])

  async function getProducts(categoryId) {
    if (!categoryId) return
    await axios.get(`/api/v1/products/types/${categoryId}`).then(response => {
      setProducts(response.data)
    }).catch((error) => {
      toast.error(error.message)
    })
  }

  async function getCategories() {
    await axios.get(`/api/v1/products/categories`).then(response => {
      setCategoties(response.data)
    }).catch((error) => {
      toast.error(error.message)
    })
  }

  async function updateOrder(e) {
    e.preventDefault()
    await axios.put(`/api/v1/orders/editOrder/${data.orderId}`, { qtd: data.quantity, categoria: data.category.value, produto: data.product.value }).then(response => {
      props.onHide()
    }).then(() => {
      toast.success("Pedido atualizado!")
    }).catch((error) => {
      toast.error(error.message)
    })
  }
  
    function handleChange(event) {
      setData({
        ...data,
        [event.target.name]: event.target.value,
      })
    }
  
    function handleChangeEnum(event) {
      setData({
        ...data,
        [event.name]: event,
      })
    }

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    <h2 className="my-2">Editar Pedido</h2>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='row py-3'>
                    <div className='col-12'>
                        <form className='form-group mx-5' onSubmit={updateOrder}>
                            <div className='row d-flex justify-content-center py-2'>
                                <div className='col-md-6'>
                                    <div className="form-group required">
                                        <label className='control-label'><strong>Categoria:</strong></label>
                                        <Select className='rounded' placeholder='' options={categoties} name='category'
                                            onChange={event => handleChangeEnum({ name: 'category', ...event })}
                                        />
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className="form-group required">
                                        <label className='control-label'><strong>Tipo do Produto:</strong></label>
                                        <Select className="rounded" placeholder='' options={products} name="product"
                                            value={products.find(product => product?.value === data?.product?.value) || ''}
                                            onChange={event => handleChangeEnum({ name: 'product', ...event })}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='row d-flex justify-content-center py-2'>
                                <div className='col-md-6'>
                                    <div className="form-group required">
                                        <label className='control-label'><strong>Quantidade:</strong></label>
                                        <input type="number" defaultValue={props.item.quantity} className="form-control rounded mr-3" id="quantity" name="quantity"
                                            placeholder="Quantidade (em Kg)" onChange={handleChange} />
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className="form-group required">
                                        <label className='control-label'><strong>Forma de pagamento:</strong></label>
                                        <Select className='rounded' isDisabled={user.ordersDelivering < 10} options={pagamentos} defaultValue={pagamentos[0]} name='paymentMethod' onChange={handleChangeEnum} />
                                    </div>
                                </div>
                            </div>
                            <div className='row pt-3'>
                                <div className='col-6'>
                                    <button className='btn btn-danger text-white text-decoration-none' to='/'>Voltar</button>
                                </div>
                                <div className='col-6 d-flex justify-content-end'>
                                    <button type='submit' className='btn btn-green'>Adicionar na lista</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}
