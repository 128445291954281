import React, { useState, useEffect, useContext } from 'react';
import { useQuery, useMutation } from 'react-query';
import { AuthContext } from '../../providers/auth';
import axios from '../../services/axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../utils/css/global.css';

function Profile() {
  const { user } = useContext(AuthContext);

  const getUserDataQuery = useQuery(['getUserData', user.id], () =>
    axios.get(`/api/v1/accounts/users/${user.id}`).then(response => response.data)
  );

  const getDataQuery = useQuery(['getData', user.companyId], () =>
    axios.get(`/api/v1/accounts/${user.companyId}`).then(response => response.data)
  );

  const updateUserMutation = useMutation(formData =>
    axios.put(`/api/v1/accounts/users/${user.id}`, formData)
  );

  const [formData, setFormData] = useState({});

  useEffect(() => {
    if (getUserDataQuery.data) {
      const {
        first_name: firstName,
        last_name: lastName,
        cpf,
        email,
        id,
        role,
        user_id: userId
      } = getUserDataQuery.data;

      setFormData({
        firstName,
        lastName,
        cpf,
        email,
        id,
        role,
        userId
      });
    }
  }, [getUserDataQuery.data]);

  const handleSubmit = e => {
    e.preventDefault();
    updateUserMutation.mutate(formData);
  };

  const handleChange = event => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  if (getUserDataQuery.isLoading || getDataQuery.isLoading) {
    return <div>Loading...</div>;
  }

  if (getUserDataQuery.isError || getDataQuery.isError) {
    return <div>Error fetching data</div>;
  }

  const {
    corporate_name: corporateName,
    fantasy_name: fantasyName,
    cnpj,
    street,
    neighborhood,
    city,
    uf,
    zipCode,
    number,
    state_registration: stateRegistration
  } = getDataQuery.data;

  return (
    <div>
      <form onSubmit={handleSubmit} className='form-group'>
        <p className='h2 text-center my-3'><strong>Dados Corporativo</strong></p>
        <div className='row'>
          <div className='col-md-6 col-sm-12'>
            <div className='form-group'>
              <label className='control-label' htmlFor="corporateName"><strong>Razão Social:</strong></label>
              <input className='form-control rounded-pill' type="text" id="corporateName" name="corporateName" value={corporateName} disabled />
            </div>
          </div>
          <div className='col-md-6 col-sm-12'>
            <div className='form-group'>
              <label className='control-label' htmlFor="fantasyName"><strong>Nome Fantasia:</strong></label>
              <input className='form-control rounded-pill' type="text" id="fantasyName" name="fantasyName" value={fantasyName} disabled />
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-6 col-sm-12'>
            <div className='form-group'>
              <label className='control-label' htmlFor="cnpj"><strong>CNPJ:</strong></label>
              <input className='form-control rounded-pill' type="text" id="cnpj" name="cnpj" value={cnpj} disabled />
            </div>
          </div>
          <div className='col-md-6 col-sm-12'>
            <div className='form-group'>
              <label className='control-label' htmlFor="IE"><strong>Inscrição Estadual:</strong></label>
              <input className='form-control rounded-pill' type="text" id="IE" name="IE" value={stateRegistration} disabled />
            </div>
          </div>
        </div>
        <hr className='bg-white' />
        <p className='h2 text-center my-3'><strong>Dados Pessoais</strong></p>
        <div className='row'>
          <div className='col-md-6 col-sm-12'>
            <div className='form-group'>
              <label className='control-label' htmlFor="firstName"><strong>Primeiro Nome:</strong></label>
              <input onChange={handleChange} className='form-control rounded-pill' type="text" id="firstName" name="firstName" defaultValue={formData.firstName} />
            </div>
          </div>
          <div className='col-md-6 col-sm-12'>
            <div className='form-group'>
              <label className='control-label' htmlFor="lastName"><strong>Sobrenome:</strong></label>
              <input onChange={handleChange} className='form-control rounded-pill' type="text" id="lastName" name="lastName" defaultValue={formData.lastName} />
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-6 col-sm-12'>
            <div className='form-group'>
              <label className='control-label' htmlFor="cpf"><strong>CPF:</strong></label>
              <input onChange={handleChange} className='form-control rounded-pill' type="text" id="cpf" name="cpf" defaultValue={formData.cpf} />
            </div>
          </div>
          <div className='col-md-6 col-sm-12'>
            <div className='form-group'>
              <label className='control-label' htmlFor="email"><strong>Email:</strong></label>
              <input onChange={handleChange} className='form-control rounded-pill' type="text" id="email" name="email" defaultValue={formData.email} />
            </div>
          </div>
        </div>
        <div className='row py-3'>
          <div className='col-6'>
            <a href='/' className='btn btn-danger'>Voltar</a>
          </div>
          <div className='col-6 d-flex justify-content-end'>
            <button type='submit' className='btn btn-green'>Salvar</button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default Profile;
