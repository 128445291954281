import React, { useState, useEffect, useContext } from 'react';
import { useQuery } from 'react-query';
import { AuthContext } from '../../providers/auth';
import axios from '../../services/axios';
import FillSidebar from '../components/sidebar';
import ItensPagination from '../components/pagination';
import 'bootstrap/dist/css/bootstrap.min.css';
import { timer } from '../../utils/js/timeToSale';
import DetailSale from './sale-detail';
import Lance from './modal-lance';
import LeilaoFiltros from '../components/leilao_filtros';
import Form from 'react-bootstrap/Form';
import { Sidebar } from 'primereact/sidebar';
import { GiHamburgerMenu } from 'react-icons/gi';

function ListProducts() {
    const { user } = useContext(AuthContext);
    const orderPerPage = 5;
    const [currentPage, setCurrentPage] = useState(1);
    const [visibleLeft, setVisibleLeft] = useState(false);
    const [filter, setFilter] = useState({
        category: user?.categories,
        product: [],
        status: [],
    });
    const [modalShow, setModalShow] = useState(null);
    const [modalLanceShow, setModalLanceShow] = useState(null);

    const getDataQuery = useQuery(
        ['getData', currentPage, filter, user?.categories],
        async () => {
            const params = buildParams();
            const response = await axios.get(
                `/api/v1/sales?page=${currentPage}&limit=${orderPerPage}&status=ACTIVE${params}`
            );
            return response.data;
        }
    );

    function closeModal(item) {
        setModalShow(null)
        setModalLanceShow(item)
    }

    useEffect(() => {
        setCurrentPage(1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter]);

    useEffect(() => {
        getDataQuery.refetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage]);

    function buildParams() {
        let params = '';
        if (filter.category.length) params = params + `&category=${filter.category}`;
        if (filter.product.length) params = params + `&product=${filter.product}`;
        return params;
    }

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-3 col-lg-2 col-2 p-0">
                    <div className="card menubtn">
                        <button
                            onClick={() => setVisibleLeft(true)}
                            className="btn btn-outline text-center"
                        >
                            <GiHamburgerMenu size={26} />
                        </button>
                        <Sidebar visible={visibleLeft} onHide={() => setVisibleLeft(false)}>
                            <FillSidebar user={user} />
                        </Sidebar>
                    </div>

                    <div className="side-list">
                        <FillSidebar user={user} />
                    </div>
                </div>
                {getDataQuery.data?.sales?.length > 0 ? (
                    <div className="col-md-8 offset-md-1 pl-md-5 col-lg-9 offset-lg-1 col-xl-10 offset-xl-0 pr-md-5 pt-5">
                        <div className="row">
                            <div className="col-2 pt-5">
                                <LeilaoFiltros filter={filter} setFilter={setFilter} />
                            </div>
                            <div className="col-10 pt-5 border-left">
                                <div className="row">
                                    <div className="col-4">
                                        <div className="input-group py-3">
                                            <span className="input-group-addon pt-2">
                                                <strong>Ordernar por:</strong>
                                            </span>
                                            <Form.Select className="mx-3" id="Exibir">
                                                <option value="1">Mais procurado</option>
                                                <option value="2">Menor Quantidade</option>
                                                <option value="3">Maior quantidade</option>
                                            </Form.Select>
                                        </div>
                                    </div>
                                    <div className="col-3">
                                        <div className="input-group py-3">
                                            <span className="input-group-addon pt-2">
                                                <strong>Exibir:</strong>
                                            </span>
                                            <Form.Select className="mx-3" id="Exibir">
                                                <option value="1">15</option>
                                                <option value="2">30</option>
                                                <option value="3">45</option>
                                            </Form.Select>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="main-box clearfix">
                                            <div className="table-responsive">
                                                <table className="table user-list text-center">
                                                    <thead>
                                                        <tr>
                                                            <th>
                                                                <span className="font-weight-bold">Categoria</span>
                                                            </th>
                                                            <th>
                                                                <span className="font-weight-bold">Tipo</span>
                                                            </th>
                                                            <th>
                                                                <span className="font-weight-bold">Lance atual</span>
                                                            </th>
                                                            <th>
                                                                <span className="font-weight-bold">Lance Inicial</span>
                                                            </th>
                                                            <th>
                                                                <span className="font-weight-bold">Tempo de leilão</span>
                                                            </th>
                                                            <th>
                                                                <span className="font-weight-bold">Criado em</span>
                                                            </th>
                                                            <th>&nbsp;</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {getDataQuery.data?.sales?.map(function (item, key) {
                                                            return (
                                                                <tr key={key} className="pt-3">
                                                                    <td className="font-weight-bold">{item.group}</td>
                                                                    <td className="font-weight-bold">{item.label}</td>
                                                                    <td>
                                                                        {item.initial_offer ? (
                                                                            item.last_offer.toLocaleString('pt-BR', {
                                                                                style: 'currency',
                                                                                currency: 'BRL',
                                                                            })
                                                                        ) : (
                                                                            'Sem lance'
                                                                        )}
                                                                    </td>
                                                                    <td>
                                                                        {item.initial_offer ? (
                                                                            item.initial_offer.toLocaleString('pt-BR', {
                                                                                style: 'currency',
                                                                                currency: 'BRL',
                                                                            })
                                                                        ) : (
                                                                            'Sem lance'
                                                                        )}
                                                                    </td>
                                                                    <td>{timer()}</td>
                                                                    <td>{new Date(item.created_at).toLocaleString()}</td>
                                                                    <td className="text-center">
                                                                        <span className="text-center">
                                                                            <button
                                                                                onClick={() => {
                                                                                    setModalShow(item);
                                                                                }}
                                                                                className="btn btn-success"
                                                                            >
                                                                                Detalhes
                                                                            </button>
                                                                            <DetailSale
                                                                                orderdetail={modalShow}
                                                                                user={user}
                                                                                show={!!modalShow?.id}
                                                                                closesalemodal={closeModal}
                                                                                onHide={() => setModalShow(null)}
                                                                            />
                                                                            <Lance
                                                                                orderdetails={modalLanceShow}
                                                                                show={modalLanceShow?.id}
                                                                                onHide={() => setModalLanceShow(null)}
                                                                                reloadList={getDataQuery.refetch}
                                                                                params={buildParams}
                                                                            />
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {getDataQuery.data?.sales?.length > 0 ? (
                                    <ItensPagination
                                        ordersPerPage={orderPerPage}
                                        totalOrders={getDataQuery.data.total}
                                        paginate={paginate}
                                        currentPage={currentPage}
                                    />
                                ) : (
                                    <></>
                                )}
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="col-md-8 offset-md-1 pl-md-5 col-lg-9 offset-lg-1 col-xl-10 offset-xl-0 pr-md-5 pt-5">
                        <div className="col-12 pt-5">
                            <div className="card shadow">
                                <h4 className="card-header">Ainda não ha leilões</h4>
                                <div className="card-body">
                                    <div>
                                        <p className="card-text">
                                            No momento ainda não temos leilões em andamento ou disponiveis, fique atento aos horarios dos leilões e algumas regras sobre
                                            os leilões.
                                        </p>
                                        <ul className="mx-3">
                                            <li className="form-text text-muted">
                                                <small>- Os pedidos tem o prazo maximo de entrega na quinta-feira.</small>
                                            </li>
                                            <li className="form-text text-muted">
                                                <small>- Os leilões ocorrem todas as segundas-feiras das 12:00 as 22:00.</small>
                                            </li>
                                            <li className="form-text text-muted">
                                                <small>- Os valores dos lances são referente ao KG do produto</small>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="d-flex justify-content-end">
                                        <a href="/" className="btn btn-green">
                                            Pagina inicial
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default ListProducts;
