import React, { useContext, useState } from 'react';
import { useMutation } from 'react-query';
import { AuthContext } from '../../providers/auth'
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../utils/css/global.css';
import { toast } from 'react-toastify';
import axios from '../../services/axios';

function ChangePassword() {
    const { user } = useContext(AuthContext);
    const [data, setData] = useState({});
  
    const changePassMutation = useMutation((requestData) =>
      axios.put(`/api/v1/accounts/password/${user.id}`, requestData)
    );
  
    const handleChange = (event) => {
      setData({
        ...data,
        [event.target.name]: event.target.value,
      });
    };

    const changePass = async () => {
        if (data?.pass !== data?.confirmPassword) {
            return alert('Senhas são diferentes');
        }

        await changePassMutation.mutateAsync({ ...data });
        setData({
            actualPass: '',
            pass: '',
            confirmPassword: '',
        }).then(() => {
            toast.success("Usuário atualizado!")
        }).catch((error) => {
            toast.error(error.response.data.message)
        })
    };

    return (
        <>
            <div className='row'>
                <div className='col-12'>
                    <p className='h2 text-center my-3'><strong>SEGURANÇA</strong></p>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-6 col-sm-12'>
                                <div className='form-group'>
                                    <label className='control-label' htmlFor="cnpj"><strong>Senha atual:</strong></label>
                                    <input 
                                    className='form-control rounded-pill rounded-pill' 
                                    type="password" 
                                    id="actualPass" 
                                    name="actualPass" 
                                    placeholder='Senha atual'
                                    value={data.actualPass} 
                                    onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className='col-md-6 col-sm-12'>
                                <div className='form-group'>
                                    <label className='control-label' htmlFor="cnpj"><strong>Nova senha:</strong></label>
                                    <input 
                                    className='form-control rounded-pill rounded-pill' 
                                    type="password" 
                                    id="pass" 
                                    name="pass" 
                                    placeholder='Nova senha' 
                                    value={data.pass} 
                                    onChange={handleChange}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-6 col-sm-12'>
                                <div className='form-group'>
                                    <label className='control-label' htmlFor="cnpj"><strong>Confirmação de senha:</strong></label>
                                    <input 
                                    className='form-control rounded-pill rounded-pill' 
                                    type="password" 
                                    id="confirmPassword" 
                                    name="confirmPassword" 
                                    placeholder='Confirme a nova senha' 
                                    value={data.confirmPassword} 
                                    onChange={handleChange}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='row py-3'>
                            <div className='col-md-6 col-6'>
                                <a href='/' className='btn btn-danger'>Voltar</a>
                            </div>
                            <div className='col-md-6 col-6 d-flex justify-content-end'>
                                <button onClick={() => changePass()} type='submit' className='btn btn-green'>Salvar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ChangePassword;