import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../utils/css/global.css'
import { Modal } from "react-bootstrap";
import { timer } from '../../utils/js/timeToSale'

export default function DetailSale(props) {

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {props.orderdetail?.label}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='container'>
                    <div className='row pt-2'>
                        <div className='col-6'>
                            <strong>Lance Atual:</strong>
                        </div>
                        <div className='col-6'>
                            {props.orderdetail?.last_offer ? (`R$ ${props.orderdetail?.last_offer} /Kg`) : 'Sem lance'} 
                        </div>
                    </div>
                    <div className='row pt-2'>
                        <div className='col-6'>
                            <strong>Lance Inicial:</strong>
                        </div>
                        <div className='col-6'>
                            {props.orderdetail?.initial_offer ? (`R$${props.orderdetail?.initial_offer} /Kg`) : 'Sem lance'} 
                        </div>
                    </div>
                    <div className='row pt-2'>
                        <div className='col-6'>
                            <strong>Tempo para leilão:</strong>
                        </div>
                        <div className='col-6'>
                            Encerra em: {timer()}
                        </div>
                    </div>
                    <div className='row pt-2'>
                        <div className='col-6'>
                            <strong>Criado em:</strong>
                        </div>
                        <div className='col-6'>
                            {new Date(props.orderdetail?.created_at).toLocaleString()}
                        </div>
                    </div>
                    <div className='row pt-2'>
                        <div className='col-6'>
                            <strong>Lances Totais:</strong>
                        </div>
                        <div className='col-6'>
                            5
                        </div>
                    </div>
                    <div className='row pt-2'>
                        <div className='col-6'>
                            <strong>Qtd. Total do pedido:</strong>
                        </div>
                        <div className='col-6'>
                            {props.orderdetail?.quantity}Kg
                        </div>
                    </div>
                    <div className='row pt-2'>
                        <div className='col-6'>
                            <strong>Preço total do pedido</strong>
                        </div>
                        <div className='col-6'>
                            {props.orderdetail?.lastOffer ? props.orderdetail?.lastOffer : props.orderdetail?.initialOffer ? props.orderdetail?.initialOffer : 'Não possui nenhum lance'}
                        </div>
                    </div>
                    <div className='row pt-4 pb-2'>
                        <div className='col-6'>
                            <button onClick={props.onHide} className="btn btn-outline-success">Voltar</button>
                        </div>
                        {
                            props.orderdetail?.status === 'ACTIVE' && props.user.kind === 'PROVIDER'? 
                                <div className='col-6 d-flex justify-content-end'>
                                    <button onClick={() => { props.closesalemodal(props.orderdetail) }} type="button" className="btn btn-success">Dar Lance</button>
                                </div>
                            :
                                <></>
                        }
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}
