import React, { useState, useEffect, createContext } from 'react';
import axios from '../services/axios';
import jwt from 'jsonwebtoken';
const token = localStorage.getItem('token')

export const AuthContext = createContext({})

export const AuthProvider = props => {
    const [user, setUser] = useState(null);

    useEffect(() => {
        if (!user && !token) logout()
        if (!user) setClient();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function logout() {
        setUser(null)
        localStorage.clear()
        if (window.location.href !== `${process.env.REACT_APP_CALLBACK_URL}/login`) {
            window.location.href = `${process.env.REACT_APP_CALLBACK_URL}/login`;
        }
    }

    async function setClient(data) {
        if (data) {
            try {
                await axios.post(`/api/v1/accounts/auth`, data).then(response => {
                    localStorage.setItem('token', response.data.rememberToken);
                    return setUser(response.data);
                })
            } catch (error) {
                localStorage.clear()
                return error.response.data.error
            }
        }
        if (token) {
            try {
                jwt.verify(token, '44eb10ef6e12da6a25cb07a92f365e55', (error, decoded) => {
                    if (error) {
                        localStorage.clear()
                        if (window.location.href !== `${process.env.REACT_APP_CALLBACK_URL}/login`)
                            window.location.href = `${process.env.REACT_APP_CALLBACK_URL}/login`;
                    } else {
                        return setUser(decoded);
                    }
                });
            } catch (error) {
                localStorage.clear()
                if (window.location.href !== `${process.env.REACT_APP_CALLBACK_URL}/login`)
                    window.location.href = `${process.env.REACT_APP_CALLBACK_URL}/login`;
            }
        }
    }

    return (
        <AuthContext.Provider value={{ user, setUser, setClient, logout }}>
            {props.children}
        </AuthContext.Provider>
    )
};