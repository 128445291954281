import React, { useState, useContext } from 'react';
import { AuthContext } from '../../providers/auth'
import FillSidebar from '../components/sidebar';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../utils/css/global.css';
import { FiUsers, FiTruck } from 'react-icons/fi';
import { RiLockPasswordLine } from 'react-icons/ri';
import { TbPhone } from 'react-icons/tb';
import Profile from './profile';
import ChangePassword from './change-password';
import ChangeAddress from './change-address';
import ChangeContact from './change-contact';
import { Sidebar } from 'primereact/sidebar';
import { GiHamburgerMenu } from 'react-icons/gi';

function MyData() {
    const { user } = useContext(AuthContext)
    const [showComponent, setShowComponent] = useState(1)
    const [visibleLeft, setVisibleLeft] = useState(false);


    function Component(value) {
        setShowComponent(value)
    }


    return (
        <div className='container-fluid'>
            <div className="row">
                <div className='col-md-3 col-lg-2 col-2 p-0'>
                    <div className="card menubtn">
                        <button onClick={() => setVisibleLeft(true)} className="btn btn-outline text-center" >
                            <GiHamburgerMenu size={26} />
                        </button>
                        <Sidebar visible={visibleLeft} onHide={() => setVisibleLeft(false)}>
                            <FillSidebar user={user} />
                        </Sidebar>
                    </div>

                    <div className='side-list'>
                        <FillSidebar user={user} />
                    </div>
                </div>
                <div className='col-md-8 offset-md-1 col-lg-10 offset-lg-0 pl-md-5 pl-lg-0 pr-md-5'>
                    <div className='row py-4 border'>
                        <div className='col-12'>
                            <span className='h2'>Meus Dados</span>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-lg-2 col-md-3 p-0 border sidebar-profile'>
                            <div className="list-group pt-3 px-2">
                                <button onClick={() => Component(1)} className={`list-group-item list-group-item-action ${showComponent === 1 && 'active'}`}>
                                    <FiUsers className="mr-2" size={24} />
                                    Perfil
                                </button>
                                <button onClick={() => Component(2)} className={`list-group-item list-group-item-action ${showComponent === 2 && 'active'}`}>
                                    <FiTruck className="mr-2" size={24} />
                                    Endereço
                                </button>
                                <button onClick={() => Component(3)} className={`list-group-item list-group-item-action ${showComponent === 3 && 'active'}`}>
                                    <TbPhone className="mr-2" size={24} />
                                    Contato
                                </button>
                                <button onClick={() => Component(4)} className={`list-group-item list-group-item-action ${showComponent === 4 && 'active'}`}>
                                    <RiLockPasswordLine className="mr-2" size={24} />
                                    Senha e segurança
                                </button>
                            </div>
                        </div>
                        <div className='col-12 text-center sidebar-profile-mobile'>
                            <div className='row py-2'>
                                <div className="btn-group btn-group-toggle" data-toggle="buttons">
                                    <label onClick={() => Component(1)} className={`btn btn-outline-success ${showComponent === 1 && 'active'}`}>
                                        <FiUsers className="mr-2" size={24} />
                                    </label>
                                    <label onClick={() => Component(2)} className={`btn btn-outline-success ${showComponent === 2 && 'active'}`}>
                                        <FiTruck className="mr-2" size={24} />
                                    </label>
                                    <label onClick={() => Component(3)} className={`btn btn-outline-success ${showComponent === 3 && 'active'}`}>
                                        <TbPhone className="mr-2" size={24} />
                                    </label>
                                    <label onClick={() => Component(4)} className={`btn btn-outline-success ${showComponent === 4 && 'active'}`}>
                                        <RiLockPasswordLine className="mr-2" size={24} />
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-10 col-md-9 col-sm-12 pt-3'>
                            {
                                {
                                    1: <Profile />,
                                    2: <ChangeAddress />,
                                    3: <ChangeContact />,
                                    4: <ChangePassword />
                                }[showComponent]
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MyData;