import React, { useState, useContext } from 'react';
import { AuthContext } from '../../../providers/auth'
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from '../../../services/axios';
import ItensPagination from '../pagination';
import { BsArrowUpRight } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { useQuery } from 'react-query';


function ListLate() {
  const { user } = useContext(AuthContext)


  const [orderPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);


  const {  data, isLoading: usersLoading, isError: usersError } = useQuery(['getLateOrders', currentPage], () =>
    axios.get(`/api/v1/orders/company/${user.companyId}?page=${currentPage}&limit=${orderPerPage}&status=LATE_ORDER`)
      .then(response => response.data)
  );

  if (usersLoading) {
    return <div>Loading...</div>;
  }

  if (usersError) {
    return <div>Error fetching data</div>;
  }

  function formatDate(data) {
    return data.split("-").reverse().join("/");
  }

  function setDate(data) {
    return new Date(data).toLocaleDateString()
  }

  function setStatus(status) {
    switch (status) {
      case 'AWAITING_PAYMENT':
        return <span className="badge badge-pill badge-warning mt-2">Aguardando pagamento</span>
      case 'AWAITING_DELIVERY':
        return <span className="badge badge-pill badge-warning mt-2">Aguardando Entrega</span>
      case 'DELIVERED':
        return <span className="badge badge-pill badge-success mt-2">Entregue</span>
      case 'OUT_FOR_DELIVERY':
        return <span className="badge badge-pill badge-success mt-2">Saiu para Entrega</span>
      case 'LATE_ORDER':
        return <span className="badge badge-pill badge-danger mt-2">Pedido Atrasado</span>
      case 'ORDER_CANCELED':
        return <span className="badge badge-pill badge-danger mt-2">Cancelado</span>
      case 'WAITING_FOR_APPROVAL':
        return <span className="badge badge-pill badge-warning mt-2">Aguardando aprovação</span>
      case 'WAITING_FOR_AUCTION':
        return <span className="badge badge-pill badge-warning mt-2">Aguardando Leilão</span>
      case 'IN_QUOTATION':
        return <span className="badge badge-pill badge-warning mt-2">Leilão em andamento</span>
      default:
        return <span className="badge badge-pill badge-secondary mt-2">Não identificado</span>
    }
  }

  if (!data.orders.length) {
    return <p>Voce não possui nenhum pedido atrasado</p>
  }
  const paginate = (pageNumber) => setCurrentPage(pageNumber);


  return (
    <>
      <div className='row orders-table'>
        <div className='col-12'>
          <div className='row pt-5'>
            <div className='col-12'>
              <table className="table user-list">
                <thead>
                  <tr className='text-center'>
                    <th><span>Pedido Num</span></th>
                    <th><span>Categoria</span></th>
                    <th><span>Tipo do produto</span></th>
                    <th><span>Criado por</span></th>
                    <th><span>Criado em</span></th>
                    <th><span>Telefone do Fornecedor</span></th>
                    <th><span>Quantidade</span></th>
                    <th><span>Prazo de entrega</span></th>
                    <th><span>Status</span></th>
                    <th><span>Lote</span></th>
                  </tr>
                </thead>
                <tbody>
                  {data.orders.map(function (order, key) {
                    return (
                      <tr key={key} className='pt-3 text-center'>
                        <td>
                          <span>{order.num_order}</span>
                        </td>
                        <td>
                          {order.group}
                        </td>
                        <td>
                          {order.label}
                        </td>
                        <td>
                          {order.first_name} {order.lastName}
                        </td>
                        <td className="text-center">
                          {setDate(order.created_at)}
                        </td>
                        <td className="text-center">
                          {order.contactNumber}
                        </td>
                        <td className='text-center'>
                          {order.quantity}
                        </td>
                        <td className='text-center'>
                          {formatDate(order.deadline)}
                        </td>
                        <td>
                          {setStatus(order.status)}
                        </td>
                        <td>
                          <Link to={`/payments/orders/${order.purchaser_id}?lote=${order.sale_date}`}><BsArrowUpRight size={16} /></Link>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {data.orders.map(function (order) {
        return (
          <div className='row orders-cards'>
            <div className='col-12'>
              <div className="card border shadow-lg my-3">
                <div className="card-body">
                  <div className='row py-3'>
                    <div className='col-4'>
                      <p className='text text-white h3'>{order.num_order}</p>
                    </div>
                    <div className='col-8 text-right'>
                      {setStatus(order.status)}
                    </div>
                  </div>
                  <div className='row py-3 text-center'>
                    <div className='row'>
                      <div className='col-6'>
                        <small>
                          <p className='p-0 m-0'><strong>Criado por: </strong></p>
                          <p className="p-0 m-0">{order.first_name} {order.last_name}</p>
                        </small>
                      </div>
                      <div className='col-6'>
                        <small>
                          <p className='p-0 m-0'><strong>Prazo de Entrega: </strong></p>
                          <p className='p-0 m-0'>{formatDate(order.deadline)}</p>
                        </small>

                      </div>
                    </div>
                  </div>
                  <div className='row p-0 m-0 text-center'>
                    <div className='row '>
                      <div className='col-12 text-center'>
                        <small>
                          <p className="p-0 m-0"><strong>{order.group}</strong> {order.label}</p>
                        </small>
                      </div>
                    </div>
                  </div>
                  <div className='row p-0 m-0 pb-2 text-center'>
                    <div className='row text-secondary'>
                      <div className='col-12 text-center'>
                        <small>
                          <p className='p-0 m-0'><strong>Contato do Fornecedor: </strong></p>
                          <p className="p-0 m-0"><strong>{order.contactNumber}</strong></p>
                        </small>
                      </div>
                    </div>
                  </div>

                  <div className='row text-center'>
                    <div className='col-6'>
                      <small>
                        <p className='p-0 m-0'><strong>Quantidade: </strong></p>
                        <p className="p-0 m-0">{order.quantity}</p>
                      </small>
                    </div>
                    <div className='col-6'>
                      <small>
                        <p className='p-0 m-0'><strong>Preço: </strong></p>
                        <p className="p-0 m-0">R${order.price}</p>
                      </small>
                    </div>
                  </div>
                  <div className='row pt-3'>
                    <div className='col-12 text-center'>
                      <Link to={`/payments/orders/${order.purchaser_id}?lote=${order.sale_date}`} className='btn btn-green'>Ver Lote <BsArrowUpRight size={16} /></Link>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      })}
      <ItensPagination ordersPerPage={orderPerPage} totalOrders={data.total} paginate={paginate} currentPage={currentPage} />
    </>
  )
}
export default ListLate;