import React, { useState, useContext } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import ItensPagination from '../components/pagination';
import axios from '../../services/axios';
import { AuthContext } from '../../providers/auth'
import { useQuery } from 'react-query';
import { NotifyContext } from '../contexts/NotifyContext';


function ReadNotify() {
  const [orderPerPage] = useState(7);
  const { currentPage, setCurrentPage } = useContext(NotifyContext); // Importe o NotifyContext
  const { user } = useContext(AuthContext)


  const { data: readNotify, isLoading, isError } = useQuery(['ReadNotifications', user.id, currentPage, orderPerPage], () =>
    axios.get(`/api/v1/notifications/${user.id}?status=READ&page=${currentPage}&limit=${orderPerPage}`)
      .then(response => response.data)
  );

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div>Error fetching data</div>;
  }

  if (!readNotify?.notifications?.length) {
    return <p className='text-center font-weight-bold h4 pt-5'>Voce não possui nenhuma notificação</p>
  }

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className='container'>
      <div className='row pt-3'>

        {
          readNotify?.notifications?.map(notificacao => (
            <div className='col-md-4 col-sm-12 py-3' key={notificacao.id}>
              <div className="card border-dark shadow">
                <div className="card-header">
                  <div className='row notify-desk'>
                    <div className='col-12 text-center'>
                      <small><span><strong>{notificacao.title}</strong></span></small>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className='row'>
                    <div className='col-12'>
                      <span>
                        {notificacao.detail}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))
        }
      </div>

      <ItensPagination ordersPerPage={orderPerPage} totalOrders={readNotify.total} paginate={paginate} currentPage={currentPage} />
    </div>
  );
}

export default ReadNotify;