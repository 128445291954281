import React, { useState, useContext } from 'react';
import { AuthContext } from '../../providers/auth';
import { useMutation, useQuery } from 'react-query';

import axios from '../../services/axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../utils/css/global.css';

function ChangeContact() {
    const { user } = useContext(AuthContext);
    const [data, setData] = useState([]);

    const getDataQuery = useQuery(['getData', user.companyId], async () => {
        const response = await axios.get(`/api/v1/accounts/${user.companyId}`);
        setData([
            ...response.data.contactNumbers,
            {
                name: "Celular",
                number: "",
                whatsapp: false,
            }
        ])
    });

    const updateDataMutation = useMutation((updatedData) =>
        axios.put(`/api/v1/accounts/${user.companyId}`, {
            contactNumbers: updatedData,
        })
    );

    const handleSubmit = () => {
        const updatedData = data.map((item) => ({
            ...item,
            user_id: user.id,
        }));

        updateDataMutation.mutate(updatedData);
    };

    const handleChange = (event) => {
        const { name, value, checked } = event.target;
        setData((prevData) => {
            const newData = [...prevData];
            const updatedItem = newData.find((item) => item.name === name);
            if (['true', 'false'].includes(value)) {
                updatedItem.whatsapp = checked;
            } else {
                updatedItem.number = value;
            }

            return newData;
        });
    };

    if (getDataQuery.isLoading) {
        return <div>Loading...</div>;
    }

    if (getDataQuery.isError) {
        return <div>Error fetching data</div>;
    }

    return (
        <>
            <div className='container'>
                <div className='row pt-2'>
                    <div className='col-12'>
                        <p className='h2 text-center my-3'><strong>Contato</strong></p>
                        <div className='row'>
                            <div className='col-md-6 col-sm-12'>
                                <div className='form-group'>
                                    <label className='control-label' htmlFor="number1"><strong>Telefone 1:</strong></label>
                                    <input readOnly={!(user.role === 'ADMIN' || user.role === 'MASTER')} className='form-control rounded-pill' placeholder='Telefone 1'
                                        name='Telefone 1'
                                        defaultValue={data[0]?.number}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className='col-md-6 col-sm-12'>
                                <div className='form-group'>
                                    <label className='control-label' htmlFor="number2"><strong>Telefone 2:</strong></label>
                                    <input readOnly={!(user.role === 'ADMIN' || user.role === 'MASTER')} className='form-control rounded-pill' placeholder='Telefone 2'
                                        name='Telefone 2'
                                        defaultValue={data[1]?.number}
                                        onChange={handleChange}

                                    />
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-6 col-sm-12'>
                                <div className='form-group'>
                                    <label className='control-label' htmlFor="celphone"><strong>Celular:</strong></label>
                                    <input className='form-control rounded-pill' placeholder='Celular'
                                        name='Celular'
                                        defaultValue={data[2]?.number}
                                        onChange={handleChange}

                                    />
                                </div>
                            </div>
                            <div className='col-md-6 col-sm-12 d-flex align-items-center'>
                                <div className="form-check pt-4">
                                    <input className="form-check-input" type="checkbox" id="defaultCheck1"
                                        name='Celular'
                                        checked={data[2]?.whatsapp}
                                        value={data[2]?.whatsapp}
                                        onChange={event => handleChange(event)} />
                                    <label className="form-check-label h6" for="defaultCheck1">
                                        WhatsApp
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className='row py-3'>
                            <div className='col-6'>
                                <a href='/' className='btn btn-danger'>Voltar</a>
                            </div>
                            <div className='col-6 d-flex justify-content-end'>
                                <button onClick={() => handleSubmit()} type='submit' className='btn btn-green'>Salvar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ChangeContact;