import React, { useState, useEffect } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Form from 'react-bootstrap/Form';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from '../../services/axios';
import { toast } from 'react-toastify';


function LeilaoFiltros({ filter, setFilter }) {
    const [categories, setCategories] = useState([]);

    function applyFilter(e, objName) {
        if (e.target.checked) {
            setFilter(prevState => ({ ...prevState, [objName]: [...filter?.[objName], e.target.value] }))
        } else {
            let tmpFilter = filter?.[objName]?.filter(value => value !== e.target.value)
            setFilter(prevState => ({ ...prevState, [objName]: tmpFilter }))
        }
    }

    useEffect(() => {
        getCategories()
    }, [])

    async function getCategories() {
        await axios.get(`/api/v1/products/categories`).then(response => {
            setCategories(response.data)
        }).catch((error) => {
            toast.error(error.message)
        })
    }

    return (
        <div className='row pt-5 '>
            <div className='col-12 pt-5'>
                <Accordion defaultActiveKey={['0']} alwaysOpen>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header> <span className="font-weight-bold">Categoria</span></Accordion.Header>
                        <Accordion.Body>
                            <Form>
                                {['checkbox'].map((type) => (
                                    <div key={`inline-${type}`} className="mb-3">
                                        <div className='row'>
                                            <div className='col-12'>
                                                {categories.map(category => (
                                                    <Form.Check
                                                        label={category.label}
                                                        name={category.label}
                                                        value={category.value}
                                                        type={type}
                                                        id={`category-${category.value}`}
                                                        key={`category-${category.value}`}
                                                        defaultChecked={filter.category.includes(category.value)}
                                                        onClick={(e) => { applyFilter(e, 'category'); }}
                                                    />
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </Form>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header><span className="font-weight-bold">Tipo</span></Accordion.Header>
                        <Accordion.Body>
                            <Form>
                                {['checkbox'].map((type) => (
                                    <div key={`default-${type}`} className="mb-3">
                                        <div className='row'>
                                            <div className='col-md-12 col-sm-12'>
                                                {categories.map(category => (
                                                    <Form.Check
                                                        label={category.label}
                                                        name={category.value}
                                                        value=""
                                                        type={type}
                                                        id={`type-${type}`}
                                                        key={`type-${category.label}`}
                                                        onClick={(e) => { applyFilter(e); }}
                                                    />
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </Form>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>
        </div>

    );
}

export default LeilaoFiltros;