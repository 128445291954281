import React, { useState, useEffect, useContext } from 'react';
import { useMutation, useQuery } from 'react-query';

import { AuthContext } from '../../providers/auth';
import axios from '../../services/axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../utils/css/global.css';

function ChangeAddress() {
  const { user } = useContext(AuthContext);
  const [data, setData] = useState({});

  const getDataQuery = useQuery(['getData', user.companyId], async () => {
    const response = await axios.get(`/api/v1/accounts/${user.companyId}`);
    const {
      corporateName,
      fantasyName,
      cnpj,
      street,
      neighborhood,
      city,
      uf,
      zip_code: zipCode,
      number,
    } = response.data;

    return {
      corporateName,
      fantasyName,
      cnpj,
      street,
      neighborhood,
      city,
      uf,
      zipCode,
      number,
    };
  });

  const updateDataMutation = useMutation((updatedData) =>
    axios.put(`/api/v1/accounts/${user.companyId}`, updatedData)
  );

  useEffect(() => {
    if (!getDataQuery.isLoading && !getDataQuery.isError) {
      setData(getDataQuery.data);
    }
  }, [getDataQuery.data, getDataQuery.isLoading, getDataQuery.isError]);

  const handleSubmit = (e) => {
    updateDataMutation.mutate(data);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  if (getDataQuery.isLoading) {
    return <div>Loading...</div>;
  }

  if (getDataQuery.isError) {
    return <div>Error fetching data</div>;
  }

    return (
        <>
        {(user.role === 'ADMIN') || (user.role === 'MASTER')  ?
            <form onSubmit={handleSubmit} className='form-group'>
                <div className='row pt-2'>
                        <div className='col-12'>
                            <p className='h2 text-center my-3'><strong>Endereço de Envio</strong></p>
                            <div className='row'>
                                <div className='col-md-6 col-sm-12'>
                                    <div className='form-group'>
                                        <label className='control-label' htmlFor="zipCode"><strong>CEP:</strong></label>
                                        <input onChange={(handleChange)} className='form-control rounded-pill' type="text" id="zipCode" name="zipCode" placeholder='zipCode' value={data?.zipCode} />
                                    </div>
                                </div>
                                <div className='col-md-6 col-sm-12'>
                                    <div className='form-group'>
                                        <label className='control-label' htmlFor="number"><strong>Numero:</strong></label>
                                        <input onChange={(handleChange)} className='form-control rounded-pill' type="text" id="number" name="number" placeholder='Numero' value={data?.number} />
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-12'>
                                    <div className='form-group'>
                                        <label className='control-label' htmlFor="street"><strong>Logradouro:</strong></label>
                                        <input onChange={(handleChange)} className='form-control rounded-pill' type="text" id="street" name="street" placeholder='Logradouro' value={data?.street} />
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-6 col-sm-12'>
                                    <div className='form-group'>
                                        <label className='control-label' htmlFor="neighborhood"><strong>Bairro:</strong></label>
                                        <input onChange={(handleChange)} className='form-control rounded-pill' type="text" id="neighborhood" name="neighborhood" placeholder='Bairro' value={data?.neighborhood} />
                                    </div>
                                </div>
                                <div className='col-md-6 col-sm-12'>
                                    <div className='form-group'>
                                        <label className='control-label' htmlFor="city"><strong>Cidade:</strong></label>
                                        <input onChange={(handleChange)} className='form-control rounded-pill' type="text" id="city" name="city" placeholder='Cidade' value={data?.city} />
                                    </div>
                                </div>
                            </div>
                            <div className='row py-3'>
                                <div className='col-6'>
                                    <a href='/' className='btn btn-danger'>Voltar</a>
                                </div>
                                <div className='col-6 d-flex justify-content-end'>
                                    <button onClick={() => handleSubmit()} type='submit' className='btn btn-green'>Salvar</button>
                                </div>
                            </div>
                        </div>
                    </div>
            </form>
            :
            <div className='row pt-2'>
                <div className='col-12'>
                    <p className='h2 text-center my-3'><strong>Endereço de Envio</strong></p>
                    <div className='row'>
                        <div className='col-md-6 col-sm-12'>
                            <div className='form-group'>
                                <label className='control-label' htmlFor="cep"><strong>CEP:</strong></label>
                                <input readOnly className='form-control rounded-pill' type="text" id="cep" name="cep" placeholder='CEP' value={data.zipCode} />
                            </div>
                        </div>
                        <div className='col-md-6 col-sm-12'>
                            <div className='form-group'>
                                <label className='control-label' htmlFor="numb"><strong>Numero:</strong></label>
                                <input readOnly className='form-control rounded-pill' type="text" id="numb" name="numb" placeholder='Numero' value={data.number} />
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='form-group'>
                                <label className='control-label' htmlFor="logradouro"><strong>Logradouro:</strong></label>
                                <input readOnly className='form-control rounded-pill' type="text" id="logradouro" name="logradouro" placeholder='Logradouro' value={data.street} />
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-6 col-sm-12'>
                            <div className='form-group'>
                                <label className='control-label' htmlFor="bairro"><strong>Bairro:</strong></label>
                                <input readOnly className='form-control rounded-pill' type="text" id="bairro" name="bairro" placeholder='Bairro' value={data.neighborhood} />
                            </div>
                        </div>
                        <div className='col-md-6 col-sm-12'>
                            <div className='form-group'>
                                <label className='control-label' htmlFor="cidade"><strong>Cidade:</strong></label>
                                <input readOnly className='form-control rounded-pill' type="text" id="cidade" name="cidade" placeholder='Cidade' value={data.city} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            }
        </>
    );
}

export default ChangeAddress;