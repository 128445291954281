import React from 'react';
import { AuthProvider } from '../providers/auth';
import PublicRoutes from './PublicRoutes';
import { ToastContainer } from 'react-toastify';

export default function ExportRoutes() {
    return (
        <AuthProvider>
            <ToastContainer />
            <PublicRoutes />
        </AuthProvider>
    )
};