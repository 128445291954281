import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../utils/css/global.css'
import { Modal, Button } from "react-bootstrap";
import axios from '../../services/axios';
import { toast } from 'react-toastify';
import Select from 'react-select';

export default function NewUserModal(props) {
    const [data, setData] = useState({
        companyId: props.user.companyId
    });

    useEffect(() => {
        if (props.userEdit) {
            getUser();
        }
    }, [props.userEdit]) // eslint-disable-line react-hooks/exhaustive-deps

    async function getUser() {
        await axios.get(`/api/v1/accounts/users/${props.userEdit}`).then((response) => {
            setData(response.data)
        }).catch((error) => {
            toast.error(error.message)
        })
    }

    async function handleSubmit(e) {
        e.preventDefault()
        try {
            if (data.pass !== data.passConfirmation) return alert("Senhas não correspondem")
            if (props.userEdit) await axios.put(`/api/v1/accounts/users/${props.userEdit}`, data);
            else await axios.post(`/api/v1/accounts/users`, data);
            props.onHide()
            if (typeof props.reloadTableUsers === 'function') props.reloadTableUsers();
        } catch (error) {
            console.log(error)
        }
    }

    function handleChange(event) {
        setData({
            ...data,
            [event.target.name]: event.target.value,
        })
    }

    function handleChangeEnum(event) {
        setData({
            ...data,
            [event.name]: event.value,
        })
    }

    const roleOptions = [
        { value: "ADMIN", label: "Administrador", name: "role" },
        { value: "USER", label: "Usuário", name: "role" }
    ]

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Cadastro de Usuario
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='container'>
                    <form onSubmit={handleSubmit} className='form-group' action="">
                        <div className='row d-flex justify-content-center py-2'>
                            <div className='col-6'>
                                <div className="form-group required">
                                    <label htmlFor="firstName" className='control-label'><strong>Primeiro Nome:</strong></label>
                                    <input type="text" className="form-control rounded mr-3" id="firstName" name="firstName"
                                        onChange={(handleChange)} placeholder="Digite o primeiro nome..." value={data?.first_name} />
                                </div>
                            </div>
                            <div className='col-6'>
                                <div className="form-group required">
                                    <label htmlFor="lastName" className='control-label'><strong>Sobrenome:</strong></label>
                                    <input type="text" className="form-control rounded mr-3" id="lastName" name="lastName"
                                        onChange={(handleChange)} placeholder="Digite o sobrenome..." value={data?.lastName} />
                                </div>
                            </div>
                        </div>
                        <div className='row d-flex justify-content-center py-2'>
                            <div className='col-6'>
                                <div className="form-group required">
                                    <label htmlFor="email" className='control-label'><strong>Email:</strong></label>
                                    <input type="text" className="form-control rounded mr-3" id="email" name="email"
                                        onChange={(handleChange)} placeholder="Digite um email..." value={data?.email} />
                                </div>
                            </div>
                            <div className='col-6'>
                                <div className="form-group required">
                                    <label htmlFor="cpf" className='control-label'><strong>CPF:</strong></label>
                                    <input type="text" className="form-control rounded mr-3" id="cpf" name="cpf"
                                        onChange={(handleChange)} placeholder="Digite o CPF..." value={data?.cpf} />
                                </div>
                            </div>
                        </div>
                        <div className='row d-flex justify-content-center py-2'>
                            <div className='col-6'>
                                <div className="form-group required">
                                    <label htmlFor="pass" className='control-label'><strong>Senha:</strong></label>
                                    <input type="password" className="form-control rounded mr-3" id="pass" name="pass"
                                        onChange={(handleChange)} placeholder="Digite uma senha..." />
                                </div>
                            </div>
                            <div className='col-6'>
                                <div className="form-group required">
                                    <label htmlFor="passConfirmation" className='control-label'><strong>Confirmação de Senha:</strong></label>
                                    <input type="password" className="form-control rounded mr-3" id="passConfirmation" name="passConfirmation"
                                        onChange={(handleChange)} placeholder="Confirme sua senha..." />
                                </div>
                            </div>
                            <div className='col-12' style={{display: (data.role === 'MASTER') ? 'none' : ''}}>
                                <div className="form-group required">
                                    <label htmlFor="role" className='control-label'><strong>Tipo de usuário</strong></label>
                                    <Select
                                        className='rounded'
                                        placeholder='Selecione o tipo de usuário...'
                                        options={roleOptions}
                                        name="role"
                                        onChange={(handleChangeEnum)}
                                        isDisabled={data.role === 'MASTER'}
                                        value={roleOptions.filter(e => e.value === data.role) || [{value: 'MASTER', label: 'Master'}]}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='row border-top'>
                            <div className='col-6 d-flex justify-content-start pt-4'>
                                <Button className='btn btn-danger' onClick={props.onHide}>Close</Button>
                            </div>
                            <div className='col-6 d-flex justify-content-end pt-4'>
                                <Button className='btn btn-green' type='submit'>{props.userEdit ? 'Atualizar' : 'Cadastrar'}</Button>
                            </div>
                        </div>
                    </form>
                </div>
            </Modal.Body>
        </Modal>
    );
}
