import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../../../providers/auth'
import 'bootstrap/dist/css/bootstrap.min.css';
import FillSidebar from '../../components/sidebar';
import Anexar from '../../../assets/anexar.svg';
import '../../../utils/css/global.css';
import axios from '../../../services/axios';
import Select from 'react-select';
import Edit from '../../../assets/edit.svg';
import { Modal, Button } from "react-bootstrap";
import ItensPagination from '../../components/pagination';
import { Sidebar } from 'primereact/sidebar';
import { GiHamburgerMenu } from 'react-icons/gi';
import { useQuery } from 'react-query';
import Card from 'react-bootstrap/Card';
import { toast } from 'react-toastify';

function ChangeStatus(props) {
  const deadline = [
    { value: 'AWAITING_DELIVERY', label: 'Aguardando entrega', name: 'deadline_status' },
    { value: 'OUT_FOR_DELIVERY', label: 'Saiu para entrega', name: 'deadline_status' },
    { value: 'DELIVERED', label: 'Entregue', name: 'deadline_status' },
    { value: 'LATE_ORDER', label: 'Atrasado', name: 'deadline_status' },
    { value: 'ORDER_CANCELED', label: 'Pedido Cancelado', name: 'deadline_status' }
  ]

  const [data, setData] = useState({
    announcement_date: new Date().toISOString().split('T')[0],
    measurement: 'KG',
  });


  function handleChangeEnum(event) {
    setData({
      ...data,
      [event.name]: event.value,
    })
  }

  function onSubmit() {
    axios.put(`/api/v1/lots/status/${props?.lotsId}`, {
      status: data?.deadline_status
    }).then(() => {
      toast.success("Status alterado!")
    }).catch((error) => {
      toast.error(error.message)
    })
  }

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Alterar Status do Pedido
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='container'>
          <form className='form-group' action="">
            <div className='row'>
              <div className='col-12'>
                <div className="form-group required">
                  <label className='control-label'><strong>Status do pedido:</strong></label>
                  <Select
                    className="rounded"
                    name="deadline_status"
                    defaultValue={deadline.find(e => e.value === props?.lotsStatus)}
                    options={deadline}
                    onChange={handleChangeEnum}
                  />
                </div>
              </div>
            </div>
          </form>
          <div className='row border-top'>
            <div className='col-6 d-flex justify-content-start pt-4'>
              <Button className='btn btn-danger' onClick={props.onHide}>Fechar</Button>
            </div>
            <div className='col-6 d-flex justify-content-end pt-4'>
              <Button className='btn btn-green' type='submit' onClick={() => onSubmit()}>Alterar</Button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

function OrderCompany() {
  const [modalShow, setModalShow] = useState(false);
  const [SalesInfo, setLots] = useState({
    orders: [],
    lots: [],
    total: 0
  });
  const [visibleLeft, setVisibleLeft] = useState(false);
  const [orderPerPage] = useState(7);
  const [currentPage, setCurrentPage] = useState(1)
  const { user } = useContext(AuthContext);
  useEffect(() => {
    getLotes()
  }, [currentPage]) // eslint-disable-line react-hooks/exhaustive-deps

  async function getLotes() {
    const urlParams = new URLSearchParams(window.location.search);
    const lote = urlParams.get('lote');
      axios.get(`/api/v1/lots/lotsSuplier/orders/${getCompanyId()}`, {
        params: { date: lote, purchaserId: user.companyId }
      }).then(response => {
        setLots(response.data);
      }).catch(error => {
        toast.error(error.message)
      })
  }

  const num_order = SalesInfo?.orders?.map(orders => (
    { value: orders.num_order, label: orders.num_order, name: 'num_order' }
  ))

  function getCompanyId() {
    const url = window.location.pathname;
    const urlParts = url.split('/');
    return urlParts[urlParts.length - 1];
  }

  const { data: client, isLoading, isError } = useQuery('getLotClient', () =>
    axios.get(`/api/v1/accounts/${getCompanyId()}`)
      .then(response => response.data)
  );

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div>Error fetching data</div>;
  }


  // function handleChangeEnum(event) {
  //   setData({
  //     ...data,
  //     [event.name]: event.value,
  //   })
  // }

  function getCompanyId() {
    const url = window.location.pathname;
    const urlParts = url.split('/');
    return urlParts[urlParts.length - 1];
  }

  const uploadNFe = async (event, productID) => {
    const formData = new FormData();
    formData.append("image", event.target.files[0]);
      await axios({
        method: "post",
        url: `/api/v1/nfe/uploadNFe/${productID}`,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      }).then(() => {
        getLotes()
      }).catch(error => {
        toast.error(error.message)
      })
  }

  async function getNFe(productID) {
    try {
      window.open(`${process.env.REACT_APP_API_URL}/api/v1/nfe/getNFe/${productID}`, '_blank', "noopener,noreferrer")
      // await axios.get(`/api/v1/nfe/getNFe/${productID}`).then(response => {
      //   window.open(`${response.data}`, '_blank', "noopener,noreferrer")
      // })
    } catch (error) {
      console.log(error)
    }
  }


  function setStatus(status) {
    switch (status) {
      case 'AWAITING_PAYMENT':
        return <span className="badge badge-pill badge-warning mt-2">Aguardando pagamento</span>
      case 'AWAITING_DELIVERY':
        return <span className="badge badge-pill badge-warning mt-2">Aguardando Entrega</span>
      case 'DELIVERED':
        return <span className="badge badge-pill badge-success mt-2">Entregue</span>
      case 'OUT_FOR_DELIVERY':
        return <span className="badge badge-pill badge-success mt-2">Saiu para Entrega</span>
      case 'LATE_ORDER':
        return <span className="badge badge-pill badge-danger mt-2">Pedido Atrasado</span>
      case 'ORDER_CANCELED':
        return <span className="badge badge-pill badge-danger mt-2">Cancelado</span>
      case 'WAITING_FOR_APPROVAL':
        return <span className="badge badge-pill badge-warning mt-2">Aguardando aprovação</span>
      case 'WAITING_FOR_AUCTION':
        return <span className="badge badge-pill badge-warning mt-2">Aguardando Leilão</span>
      case 'IN_QUOTATION':
        return <span className="badge badge-pill badge-warning mt-2">Leilão em andamento</span>
      default:
        return <span className="badge badge-pill badge-secondary mt-2">Não identificado</span>
    }
  }

  return (
    <div className='container-fluid'>
      <div className="row">
        <div className='col-2 p-0'>
          <div className="card menubtn">
            <button onClick={() => setVisibleLeft(true)} className="btn btn-outline text-center" >
              <GiHamburgerMenu size={26} />
            </button>
            <Sidebar visible={visibleLeft} onHide={() => setVisibleLeft(false)}>
              <FillSidebar user={user} />
            </Sidebar>
          </div>

          <div className='side-list' >
            <FillSidebar user={user} />
          </div>
        </div>
        <div className='col-md-10 col-sm-12 pt-5'>
          <div className='row'>
            <div className='col-12'>
              <div className='row'>
                <div className='col-12'>

                  <div className='row'>
                    <div className='col-6'>
                      <Card>
                        <Card.Body>
                          <p className='h2 text-center py-3'>Informações da empresa</p>
                          <div className='row'>
                            <div className='col-6'>
                              <label className='p-0'><strong>Nome fantasia:</strong></label>
                              <p className='p-0'>{client.fantasy_name}</p>
                            </div>
                            <div className='col-6'>
                              <label className='p-0'><strong>Razão Social:</strong></label>
                              <p>{client.corporate_name}</p>
                            </div>
                          </div>
                          <div className='row'>
                            <div className='col-6'>
                              <label className='p-0'><strong>CNPJ:</strong></label>
                              <p>{client.cnpj}</p>
                            </div>
                            <div className='col-6'>
                              <label className='p-0'><strong>Inscrição Estadual:</strong></label>
                              <p>{client.state_registration}</p>
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    </div>
                    <div className='col-6'>
                      <Card>
                        <Card.Body>
                          <p className='h2 text-center py-3'>Endereço de entrega</p>
                          <div className='row'>
                            <div className='col-6'>
                              <label className='p-0'><strong>Logradouro:</strong></label>
                              <p>{client.street}</p>
                            </div>
                            <div className='col-3'>
                              <label className='p-0'><strong>Numero:</strong></label>
                              <p>{client.number}</p>
                            </div>
                            <div className='col-3'>
                              <label className='p-0'><strong>Bairro:</strong></label>
                              <p>{client.neighborhood}</p>
                            </div>
                          </div>
                          <div className='row'>
                            <div className='col-6'>
                              <label className='p-0'><strong>Cidade:</strong></label>
                              <p>{client.city}</p>
                            </div>
                            <div className='col-3'>
                              <label className='p-0'><strong>Estado:</strong></label>
                              <p>{client.uf}</p>
                            </div>
                            <div className='col-3'>
                              <label className='p-0'><strong>CEP:</strong></label>
                              <p>{client.zip_code}</p>
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    </div>
                  </div>

                </div>
              </div>
              <div className='row pt-2 px-5'>
                <div className='col-12 d-flex justify-content-end'>
                  <div className='px-5'>
                    {SalesInfo.lots.length > 0 && SalesInfo.lots[0].NFe ?
                      <label className='custom-file-upload'>
                        <button className='btn btn-light' onClick={() => getNFe(SalesInfo.lots[0].id)}>
                          <img alt='anexaricon' width='24px' height='24px' src={Anexar} />
                          <span className='pl-2'><strong><small>Visualizar a Nota fiscal</small></strong></span>
                        </button>
                      </label>
                      :
                      <label className="custom-file-upload d-flex justify-content-center">
                        <input type="file" onChange={(e) => {
                          uploadNFe(e, SalesInfo.lots[0].id)
                        }} />
                        <img alt='anexaricon' className='mt-2' width='24px' height='24px' src={Anexar} />
                        <div className='d-block'>
                          <span className='pl-4'>Anexar</span><br />
                          <span className='pl-3'>Nota fiscal</span>
                        </div>
                      </label>
                    }
                  </div>
                  <div>
                    <span className="text-right">
                      <button onClick={() => setModalShow(true)} className='btn btn-light'>
                        <img alt='editicon' src={Edit} width='16' height='16' />Alterar Status
                      </button>
                      <ChangeStatus
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                        lotsId={SalesInfo?.lots[0]?.id}
                        lotsStatus={SalesInfo?.lots[0]?.lots_status}
                      />
                    </span>
                  </div>
                </div>

              </div>
              <div className='row px-5'>
                <div className='col-12'>
                  <div className='row pt-5 orders-table'>
                    <div className='col-12'>
                      <table className="table user-list">
                        <thead>
                          <tr className='text-center'>
                            <th><span>Pedido Num</span></th>
                            <th><span>Pedido</span></th>
                            <th><span>Quantidade</span></th>
                            <th><span>Preço</span></th>
                          </tr>
                        </thead>
                        <tbody>
                          {SalesInfo.orders.slice((currentPage - 1) * orderPerPage, currentPage * orderPerPage).map(function (order, key) {
                            return (
                              <tr key={key} className='pt-3 text-center'>
                                <td>
                                  <span>{order.num_order}</span>
                                </td>
                                <td className="text-center">
                                  {order.group}: <strong>{order.label}</strong>
                                </td>
                                <td className='text-center'>
                                  {order.quantity}
                                </td>
                                <td className='text-center'>
                                  R${order.price}
                                </td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>

                  {SalesInfo.orders.map(function (order) {
                    return (
                      <div className='row orders-cards'>
                        <div className='col-12'>
                          <div className="card border shadow-lg my-3">
                            <div className="card-body">
                              <div className='row py-3'>
                                <div className='col-4'>
                                  <p className='text text-white h3'>{order.num_order}</p>
                                </div>
                                <div className='col-8 text-right'>
                                  {setStatus(order.status)}
                                </div>
                              </div>
                              <div className='row py-3 text-center'>
                                <div className='row'>
                                  <div className='col-6'>
                                    <small>
                                      <p className='p-0 m-0'><strong>Cliente: </strong></p>
                                      <p className="p-0 m-0">{order.fantasy_name}</p>
                                    </small>
                                  </div>
                                  <div className='col-6'>

                                  </div>
                                </div>
                              </div>
                              <div className='row text-center'>
                                <div className='col-6'>
                                  <small>
                                    <p className='p-0 m-0'><strong>Quantidade: </strong></p>
                                    <p className="p-0 m-0">{order.quantity}</p>
                                  </small>
                                </div>
                                <div className='col-6'>
                                  <small>
                                    <p className='p-0 m-0'><strong>Valor a receber: </strong></p>
                                    <p className='p-0 m-0'>{order.price}</p>
                                  </small>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
              {SalesInfo.orders.length ?
                <ItensPagination ordersPerPage={orderPerPage} totalOrders={SalesInfo.orders.length} paginate={currentPage => setCurrentPage(currentPage)} currentPage={currentPage} />
                :
                <></>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrderCompany;