import React, {useState} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../utils/css/global.css'
import { Modal } from "react-bootstrap";
import axios from '../../services/axios';
import { toast } from 'react-toastify';
import { FaStar } from "react-icons/fa";


export default function RatingModal(props) {
    const [data, setData] = useState({
        score: 0,
        comments: '',
        orderId: props.order.id
    })

    async function sendRating() {
        await axios.post(`/api/v1/ratings/company/${props.order.company_id}`, data).then(() => {
            props.onHide()
            props.getOrders()
        }).then(() => {
            toast.success("Avaliação enviada!")
        }).catch((error) => {
            toast.error(error.message)
        })
    }

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    <h3 className="mb-3">Avalie como foi seu pedido</h3>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='row'>
                    <div className='col-12'>
                        <div className="d-flex justify-content-center" style={{ "font-size": "40px" }}>
                            {[...Array(5)].map((item, index) => {
                                const givenRating = index + 1;
                                return (
                                    <label>
                                        <input
                                            className="d-none"
                                            type="radio"
                                            value={givenRating}
                                            onClick={() => {
                                                setData(prevState => ({...prevState, score : givenRating}) );
                                            }}
                                        />
                                        <div style={{ "cursor": "pointer" }}>
                                            <FaStar
                                                color={
                                                    givenRating < data.score || givenRating === data.score
                                                        ? "000"
                                                        : "rgb(192,192,192)"
                                                }
                                            />
                                        </div>
                                    </label>
                                );
                            })}
                        </div>
                    </div>
                </div>
                <div className='row py-3'>
                    <div className='col-12'>
                        <div className="form-group">
                            <label><strong>Comentario:</strong></label>
                            <textarea onChange={(event) => { setData(prevState => ({...prevState, comments : event.target.value}) ); }} className="form-control" id="comment" rows="3">
                            </textarea>
                        </div>
                    </div>
                </div>
                <div className='row pt-4 pb-2'>
                    <div className='col-6 d-flex justify-content-start'>
                        <button onClick={props.onHide} className="btn btn-outline-success">Fechar</button>
                    </div>
                    <div className='col-6 d-flex justify-content-end'>
                        <button onClick={() => sendRating()} className="btn btn-success">Enviar</button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}
