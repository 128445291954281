import React from "react";
import ReactDOM from "react-dom/client";
import Routes from './routes';
import { QueryClient, QueryClientProvider } from 'react-query';
import 'react-loading-skeleton/dist/skeleton.css'
import 'react-toastify/dist/ReactToastify.css';
const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <QueryClientProvider client={queryClient}>
    <React.StrictMode>
      <Routes />
    </React.StrictMode>
  </QueryClientProvider>

);
