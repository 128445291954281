import React, { useState, useContext } from 'react';
import { AuthContext } from '../../../providers/auth'
import 'bootstrap/dist/css/bootstrap.min.css';
import FillSidebar from '../../components/sidebar';
import '../../../utils/css/global.css'
import Ok from '../../../assets/status_ok.svg';
import Cancel from '../../../assets/status_cancel.svg';
import Waiting from '../../../assets/status_waiting.svg';
import { Link } from 'react-router-dom';
import axios from '../../../services/axios';
import { Sidebar } from 'primereact/sidebar';
import { GiHamburgerMenu } from 'react-icons/gi';
import { toast } from 'react-toastify';
import { useQuery } from 'react-query';

function MyOrdersCompany() {

  const { user } = useContext(AuthContext);
  const [visibleLeft, setVisibleLeft] = useState(false);


  const { data, isLoading, isError } = useQuery('getLotsPerSale', async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const lote = urlParams.get('lote');


    const response = await axios.get(`/api/v1/lots/lotsSuplier/${user.companyId}`, {
      params: { date: lote }
    }).catch((error) => {
      toast.error(error.message)
    })
    return response.data;

});

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div>Error fetching data</div>;
  }

  function setDate(data) {
    return new Date(data).toLocaleDateString()
  }


  function setUrlDate(data) {
    const dateString = new Date(data).toLocaleDateString().substr(0, 10);;
    const [day, month, year] = dateString.split('/');
    const dateObject = new Date(`${year}-${month}-${day}`);
    const isoDateString = dateObject.toISOString().split('T')[0];
    return isoDateString
  }
  return (
    <div className='container-fluid'>
      <div className="row">
        <div className='col-md-3 col-lg-2 col-2 p-0'>
          <div className="card menubtn">
            <button onClick={() => setVisibleLeft(true)} className="btn btn-outline text-center" >
              <GiHamburgerMenu size={26} />
            </button>
            <Sidebar visible={visibleLeft} onHide={() => setVisibleLeft(false)}>
              <FillSidebar user={user} />
            </Sidebar>
          </div>

          <div className='side-list' >
            <FillSidebar user={user} />
          </div>
        </div>
        <div className='col-md-8 offset-md-1 col-lg-10 offset-lg-0 pl-md-4 pl-lg-0 pr-md-5 pt-4'>
          {data.companys.length ?
            <>
              <div className='row border rounded shadow mb-5'>
                <div className='col-12'>
                  <div className='row pt-3'>
                    <div className='col-12'>
                      <p className='h3'>Bem vindo, {user.firstName}</p>
                      <p className=''>Resumo das suas atividades dentro da plataforma Fillstock.</p>
                    </div>
                  </div>
                  <div className='row py-4 text-center'>
                    <div className='col-4 '>
                      <img alt='okicon' width='24px' height='24px' src={Ok} />
                      <span className='text-success pl-2'>15 lotes Abertos</span>
                    </div>
                    <div className='col-4'>
                      <img alt='waitingicon' width='24px' height='24px' src={Waiting} />
                      <span className='text-warning pl-2'>23 lotes A caminho</span>
                    </div>
                    <div className='col-4'>
                      <img alt='cancelicon' width='24px' height='24px' src={Cancel} />
                      <span className='text-danger pl-2'>5 lotes Finalizados</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className='row'>
                {data.companys.map(company => (
                  <div className='col-md-3 col-sm-12 py-2'>
                    <div className="card border shadow-lg">
                      <div className="card-body">
                        <div className='row py-3 text-center'>
                          <div className='col-6'>
                            <p className='p-0 m-0'><strong>Nome Fantasia: </strong></p>
                            <p className='p-0 m-0'>{company.fantasy_name}</p>
                          </div>
                          <div className='col-6'>
                            <p className='p-0 m-0'><strong>Razão Social: </strong></p>
                            <p className='p-0 m-0'>{company.corporate_name}</p>
                          </div>
                        </div>
                        <div className='row text-center'>
                          <div className='col-6'>
                            <small>
                              <p className='p-0 m-0'><strong>CNPJ: </strong></p>
                              <p className='p-0 m-0'>{company.cnpj}</p>
                            </small>
                          </div>
                          <div className='col-6'>
                            <small>
                              <p className='p-0 m-0'><strong>Criado em: </strong></p>
                              <p className='p-0 m-0'>{setDate(company.created_at)}</p>
                            </small>
                          </div>
                        </div>
                        <div className='row pt-4'>
                          <div className='col-12 text-center sales-link'>
                            <Link to={`/lots/orders/${company.company_id}?lote=${setUrlDate(company.created_at)}`} className="sales-orders">Ver pedido</Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </>
            :
            <div className='row'>
              <div className='col p-0'>
                <div className="card shadow">
                  <h4 className="card-header">Sem leilões</h4>
                  <div className="card-body">
                    <div>
                      <p className="card-text">No momento você não possui nenhum leilão, caso deseja participar dos leilões
                        <a className='text-decoration-none text-green font-weight-bold' href='list'> clique aqui</a>
                      </p>
                      <p>Os leilões ocorrem todas as segundas-feiras das 12:00 as 22:00.</p>
                    </div>
                    <div className='d-flex justify-content-end'>
                      <a href="/" className="btn btn-green">Pagina inicial</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }

        </div>
      </div>
    </div>
  );
}

export default MyOrdersCompany;