import React, { useState, useContext } from 'react';
import { AuthContext } from '../../providers/auth'
import 'bootstrap/dist/css/bootstrap.min.css';
import FillSidebar from '../components/sidebar';
import '../../utils/css/global.css'
import { Link } from 'react-router-dom';
import axios from '../../services/axios';
import { Sidebar } from 'primereact/sidebar';
import { GiHamburgerMenu } from 'react-icons/gi';
import ItensPagination from '../components/pagination';
import { toast } from 'react-toastify';
import { useQuery } from 'react-query';

function LotesPerCompany() {
    const [orderPerPage] = useState(8);
    const [currentPage, setCurrentPage] = useState(1)
    const { user } = useContext(AuthContext);
    const [visibleLeft, setVisibleLeft] = useState(false);


    function getCompanyId() {
        const url = window.location.pathname;
        const urlParts = url.split('/');
        return urlParts[urlParts.length - 1];
    }
    const { data, isLoading, isError } = useQuery('getLotes', async () => {
        const url = window.location.pathname;
        const urlParts = url.split('/');
        const lastItem = urlParts[urlParts.length - 1];

        const response = await axios.get(`/api/v1/lots/lotes/${lastItem}`, {
            params: { userCompanyId: user.companyId }
        }).catch((error) => {
            toast.error(error.message)
        })
        return response.data;
    });

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (isError) {
        return <div>Error fetching data</div>;
    }


    function setDate(data) {
        return new Date(data).toLocaleDateString()
    }

    return (
        <div className='container-fluid'>
            <div className="row">
                <div className='col-md-3 col-lg-2 col-2 p-0'>
                    <div className="card menubtn">
                        <button onClick={() => setVisibleLeft(true)} className="btn btn-outline text-center" >
                            <GiHamburgerMenu size={26} />
                        </button>
                        <Sidebar visible={visibleLeft} onHide={() => setVisibleLeft(false)}>
                            <FillSidebar user={user} />
                        </Sidebar>
                    </div>

                    <div className='side-list' >
                        <FillSidebar user={user} />
                    </div>
                </div>
                <div className='col-md-8 offset-md-1 col-lg-8 col-xl-10 offset-xl-0 offset-lg-2 pl-xl-4 pl-md-4 pl-lg-0 pl-xl-4 pr-md-5 pt-4'>
                    {data.lotes.length ?
                        <>
                            <div className='row border rounded shadow mb-5'>
                                <div className='col-12'>
                                    <div className='row pt-3'>
                                        <div className='col-12'>
                                            <p className='h3'>Bem vindo, {user.firstName}</p>
                                            <p className=''>Esta pagina é dedicada aos pagamentos</p>
                                        </div>
                                    </div>
                                    <div className='row py-4'>
                                        <div className='col-12'>
                                            <p>Olá,

                                                Esta página é dedicada à visualização das empresas que venceram os leilões dos pedidos que você criou.<br /> Aqui, você pode verificar as informações das empresas, como o nome fantasia e o nome corporativo, bem como a data da primeira venda feita por elas.

                                                Essas informações podem ser úteis para ajudá-lo a avaliar o desempenho das empresas que você escolheu para seus pedidos e para identificar aquelas que mais se destacaram em termos de eficiência e qualidade.

                                                Esperamos que essas informações ajudem você a tomar decisões mais informadas em relação às suas escolhas de fornecedores. Em caso de dúvidas ou problemas, não hesite em entrar em contato conosco. Estamos sempre disponíveis para ajudá-lo.

                                                Obrigado por usar nossa plataforma!</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='row'>
                                {data.lotes.slice((currentPage - 1) * orderPerPage, currentPage * orderPerPage).map(lote => (
                                    <div className='col-md-3 col-sm-12 py-2'>
                                        <div className="card border shadow-lg">
                                            <div className="card-body">
                                                <div className='row py-3'>
                                                    <div className='col-12 text-center'>
                                                        <p className='h4'>Lote {setDate(lote.first_sale_date)}</p>
                                                    </div>
                                                </div>
                                                <div className='row pt-4'>
                                                    <div className='col-12 text-center sales-link'>
                                                        <Link to={`/payments/orders/${getCompanyId()}?lote=${lote.first_sale_date}`} className="sales-orders">Ver pedidos</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </>
                        :
                        <div className='row'>
                            <div className='col p-0'>
                                <div className="card shadow">
                                    <h4 className="card-header">Sem Pagamentos</h4>
                                    <div className="card-body">
                                        <div>
                                            <p className="card-text">No momento você não possui nenhum pagamento.</p>
                                        </div>
                                        <div className='d-flex justify-content-end'>
                                            <a href="/" className="btn btn-green">Pagina inicial</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    {data.lotes.length ?
                        <ItensPagination ordersPerPage={orderPerPage} totalOrders={data.lotes.length} paginate={currentPage => setCurrentPage(currentPage)} currentPage={currentPage} />
                        :
                        <></>
                    }
                </div>
            </div>
        </div>
    );
}

export default LotesPerCompany;