import React, { useState, useContext } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import FillSidebar from '../components/sidebar';
import ItensPagination from '../components/pagination';
import axios from '../../services/axios';
import Accept from '../../assets/accept.svg';
import Trash from '../../assets/trash.svg';
import { AuthContext } from '../../providers/auth';
import { Sidebar } from 'primereact/sidebar';
import { GiHamburgerMenu } from 'react-icons/gi';
import { useQuery, useMutation } from 'react-query';
import Skeleton from 'react-loading-skeleton'
import { toast } from 'react-toastify';

function Approve() {
  const [orderPerPage] = useState(7);
  const [currentPage, setCurrentPage] = useState(1);
  const [visibleLeft, setVisibleLeft] = useState(false);
  const { user } = useContext(AuthContext)

  const sendRequestMutation = useMutation(({ orderId, action }) =>
    axios.put(`/api/v1/orders/${orderId}`, { approveType: action })
  );

  const sendRequest = async (orderId, action) => {
    await sendRequestMutation.mutateAsync({ orderId, action }).then(() => {
      toast.success("Ação realizada!")
    }).catch((error) => {
      toast.error(error.message)
    })
    handleRefresh();
  };
  const { data, isLoading: usersLoading, refetch: refetchApprove } = useQuery(['getApprover', currentPage], () =>
    axios.get(`/api/v1/orders/approve/${user.companyId}?page=${currentPage}&limit=${orderPerPage}`)
      .then(response => response.data)
      .catch((error) => {
        toast.error(error.message)
    })
  );

  if (usersLoading) {
    return <div>
      <div className='container-fluid'>
        <div className="row">
          <div className='col-md-3 col-lg-2 col-2 p-0'>
            <div className="card menubtn">
              <button onClick={() => setVisibleLeft(true)} className="btn btn-outline text-center" >
                <GiHamburgerMenu size={26} />
              </button>
              <Sidebar visible={visibleLeft} onHide={() => setVisibleLeft(false)}>
                <FillSidebar user={user} />
              </Sidebar>
            </div>

            <div className='side-list'>
              <FillSidebar user={user} />
            </div>
          </div>
          <div className='col-md-8 offset-md-1 pl-md-5 col-lg-9 offset-lg-1 col-xl-8 offset-xl-1 pr-md-5 pt-5'>
            <div className='row'>
              <Skeleton count={1} height={140} />
            </div>
          </div>
        </div>
      </div>
    </div>
  }

  const handleRefresh = () => {
    refetchApprove();
  };

  function setBankPagament(bank) {
    switch (bank) {
      case 'bank_ticket':
        return "Boleto Bancario"
      default:
        return "Não identificado"
    }
  }

  function setDate(data) {
    return new Date(data).toLocaleDateString()
  }

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className='container-fluid'>
      <div className="row">
        <div className='col-md-3 col-lg-2 col-2 p-0'>
          <div className="card menubtn">
            <button onClick={() => setVisibleLeft(true)} className="btn btn-outline text-center" >
              <GiHamburgerMenu size={26} />
            </button>
            <Sidebar visible={visibleLeft} onHide={() => setVisibleLeft(false)}>
              <FillSidebar user={user} />
            </Sidebar>
          </div>

          <div className='side-list'>
            <FillSidebar user={user} />
          </div>
        </div>
        <div className='col-md-8 offset-md-1 pl-md-5 col-lg-9 offset-lg-1 col-xl-8 offset-xl-1 pr-md-5 pt-5'>
          {
            data?.total > 0 ?
              <div>
                <div className='row border rounded shadow mb-5'>
                  <div className='col-12'>
                    <div className='row pt-3'>
                      <div className='col-12 '>
                        <p className='h2 text-center py-3'>Pagina de Aprovação</p>
                        <p className='pt-3 pl-3 text-secondary'>
                          Você possui {data.total} pedido(s) para aprovar, aqui é a pagina onde é possivel aceitar/recusar a criação de pedidos feitos por
                          usuarios do sistema.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row'>
                  {data?.orders?.map(order => (
                    <div className='col-3'>
                      <div className="card border shadow-lg">
                        <div className="card-body">
                          <div className='row py-3'>
                            <div className='col-12 text-right'>
                              <span className="badge badge-pill badge-warning">{order.status === 'WAITING_FOR_APPROVAL' ? 'Aguardando aprovação' : 'Pendente'}</span>
                            </div>
                          </div>
                          <div className='row pt-3 text-center'>
                            <div className='col-6'>
                              <small>
                                <p className='p-0 m-0'><strong>Categoria: </strong></p>
                                <p className='p-0 m-0'>{order.group}</p>
                              </small>
                            </div>
                            <div className='col-6'>
                              <small>
                                <p className='p-0 m-0'><strong>Tipo: </strong></p>
                                <p className='p-0 m-0'>{order.label}</p>
                              </small>
                            </div>
                          </div>
                          <div className='row py-3 text-center'>
                            <div className='col-6'>
                              <small>
                                <p className='p-0 m-0'><strong>Quantidade: </strong></p>
                                <p className='p-0 m-0'>{order.quantity}Kg</p>
                              </small>
                            </div>
                            <div className='col-6'>
                              <small>
                                <p className='p-0 m-0'><strong>Pagamento: </strong></p>
                                <p className='p-0 m-0'>{setBankPagament(order.paymentMethod)}</p>
                              </small>
                            </div>
                          </div>
                          <div className='row text-center'>
                            <div className='col-6'>
                              <small>
                                <p className='p-0 m-0'><strong>Criado por: </strong></p>
                                <p className='p-0 m-0'>{order.first_name} {order.lastName}</p>
                              </small>
                            </div>
                            <div className='col-6'>
                              <small>
                                <p className='p-0 m-0'><strong>Criado em: </strong></p>
                                <p className='p-0 m-0'>{setDate(order.created_at)}</p>
                              </small>
                            </div>
                          </div>
                          <div className='row pt-4'>
                            <div className='col-6 text-center'>
                              <button onClick={() => sendRequest(order.id, 'declined')} className='btn'><img alt='trashicon' src={Trash} width='20px' height='20px' /></button>
                            </div>
                            <div className='col-6 text-center'>
                              <button onClick={() => sendRequest(order.id, 'approved')} className='btn mr-3'><img alt='Accept Icon' src={Accept} width='20px' height='20px' /></button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                {
                  data &&
                  <ItensPagination ordersPerPage={orderPerPage} totalOrders={data.total} paginate={paginate} currentPage={currentPage} />
                }
              </div>
              :
              <div>
                <div className='row'>
                  <div className='col'>
                    <div className="card shadow">
                      <h4 className="card-header">Sem aprovações</h4>
                      <div className="card-body">
                        <div>
                          <p className="card-text">No momento você não possui nenhuma criação de pedido para ser aprovado, se deseja criar um novo pedido
                            <a className='text-decoration-none text-green font-weight-bold' href='add/order'> clique aqui</a>
                          </p>
                        </div>
                        <div className='d-flex justify-content-end'>
                          <a href="/" className="btn btn-green">Pagina inicial</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          }
        </div>
      </div>
    </div>
  );
}

export default Approve;