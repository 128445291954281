import React, { useContext, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import FillSidebar from '../components/sidebar';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import ReadNotify from './read-notify';
import NotReadNotify from './not-read-notify';
import { AuthContext } from '../../providers/auth';
import { Sidebar } from 'primereact/sidebar';
import { GiHamburgerMenu } from 'react-icons/gi';
import { NotifyProvider } from '../contexts/NotifyContext';


function Notify() {
  const { user } = useContext(AuthContext)
  const [visibleLeft, setVisibleLeft] = useState(false);

  return (
    <NotifyProvider>
      <div className='container-fluid'>
        <div className="row">
          <div className='col-md-3 col-lg-2 col-2 p-0'>
            <div className="card menubtn">
              <button onClick={() => setVisibleLeft(true)} className="btn btn-outline text-center" >
                <GiHamburgerMenu size={26} />
              </button>
              <Sidebar visible={visibleLeft} onHide={() => setVisibleLeft(false)}>
                <FillSidebar user={user} />
              </Sidebar>
            </div>

            <div className='side-list'>
              <FillSidebar user={user} />
            </div>
          </div>
          <div className='col-md-8 offset-md-1 col-lg-9 offset-lg-1 col-xl-8 offset-xl-1 px-lg-5 pl-md-5 pr-md-5 pt-5'>
            <div className='row'>
              <div className='col-12'>
                <Tabs
                  defaultActiveKey="not-read"
                  id="uncontrolled-tab-example"
                  className="mb-3"
                >
                  <Tab eventKey="read" title="Lidos">
                    <ReadNotify />
                  </Tab>
                  <Tab eventKey="not-read" title="Não lidos">
                    <NotReadNotify />
                  </Tab>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
    </NotifyProvider>
  );
}

export default Notify;