import React, { useContext, useState } from 'react';
import { AuthContext } from '../../providers/auth'
import 'bootstrap/dist/css/bootstrap.min.css';
import FillSidebar from '../components/sidebar'
import { FaClock } from 'react-icons/fa';
import Ok from '../../assets/status_ok.svg';
import Cancel from '../../assets/status_cancel.svg';
import Waiting from '../../assets/status_waiting.svg';
import { Sidebar } from 'primereact/sidebar';
import axios from '../../services/axios';
import { GiHamburgerMenu } from 'react-icons/gi';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';

function Home() {
  const { user } = useContext(AuthContext)
  const [visibleLeft, setVisibleLeft] = useState(false);

  const { data, isLoading } = useQuery('orders', () =>
    axios.get(`/api/v1/orders/dashboard/${user.companyId}`)
      .then(response => response.data)
      .catch((error) => {
        toast.error(error.message)
      })
  );

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className='container-fluid'>
      <div className="row">
        <div className='col-md-3 col-lg-2 col-2 p-0'>
          <div className="card menubtn">
            <button onClick={() => setVisibleLeft(true)} className="btn btn-outline text-center" >
              <GiHamburgerMenu size={26} />
            </button>
            <Sidebar visible={visibleLeft} onHide={() => setVisibleLeft(false)}>
              <FillSidebar user={user} />
            </Sidebar>
          </div>

          <div className='side-list'>
            <FillSidebar user={user} />
          </div>
        </div>
        <div className='col-md-7 offset-md-2 pr-md-5 col-lg-8 offset-lg-2 pl-lg-5 col-xl-8 offset-xl-1 pt-5'>
          <div className='row border rounded shadow mb-5'>
            <div className='col-12'>
              <div className='row pt-3'>
                <div className='col-12'>
                  <p className='h3'>Bem vindo, {user.firstName}</p>
                  <p className=''>Resumo das suas atividades dentro da plataforma Fillstock.</p>
                </div>
              </div>
              <div className='row py-4 text-center'>
                <div className='col-3 '>
                  <FaClock className="text-primary" size={24} />
                  <span className='text-primary pl-2'>{data?.ordersPending || 0} pedidos Aguardando Leilão</span>
                </div>
                <div className='col-3 '>
                  <img width='24px' height='24px' src={Ok} alt='Status Ok' />
                  <span className='text-success pl-2'>{data?.ordersDelivered || 0} pedidos Efetivado</span>
                </div>
                <div className='col-3'>
                  <img width='24px' height='24px' src={Waiting} alt='Status Aguardando' />
                  <span className='text-warning pl-2'>{data?.ordersDelivering || 0} pedidos A caminho</span>
                </div>
                <div className='col-3'>
                  <img width='24px' height='24px' src={Cancel} alt='Status Cancelados' />
                  <span className='text-danger pl-2'>{data?.ordersCanceled || 0} pedidos Cancelados</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;