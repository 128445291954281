import React, { useState, useContext } from 'react';
import { useQuery } from 'react-query';

import { AuthContext } from '../../../providers/auth'
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from '../../../services/axios';
import ItensPagination from '../pagination';
import RatingModal from '../../orders/rating-modal';
import { FaStar } from 'react-icons/fa';
import { BsArrowUpRight } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

function ListDelivered() {
  const [modalShow, setModalShow] = useState(null);
  const { user } = useContext(AuthContext)

  const [orderPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);

  const { data, isLoading: usersLoading, refetch: refetchDeliveredOrders } = useQuery(['getDeliveredOrders', currentPage], () =>
    axios.get(`/api/v1/orders/company/${user.companyId}?page=${currentPage}&limit=${orderPerPage}&status=DELIVERED`)
      .then(response => response.data)
      .catch((error) => {
        toast.error(error.message)
      })
  );

  if (usersLoading) {
    return <div>Loading...</div>;
  }

  const handleRefresh = () => {
    refetchDeliveredOrders();
};

  function setDate(data) {
    return new Date(data).toLocaleDateString()
  }

  function setStatus(status) {
    switch (status) {
      case 'AWAITING_PAYMENT':
        return <span className="badge badge-pill badge-warning mt-2">Aguardando pagamento</span>
      case 'AWAITING_DELIVERY':
        return <span className="badge badge-pill badge-warning mt-2">Aguardando Entrega</span>
      case 'DELIVERED':
        return <span className="badge badge-pill badge-success mt-2">Entregue</span>
      case 'OUT_FOR_DELIVERY':
        return <span className="badge badge-pill badge-success mt-2">Saiu para Entrega</span>
      case 'LATE_ORDER':
        return <span className="badge badge-pill badge-danger mt-2">Pedido Atrasado</span>
      case 'ORDER_CANCELED':
        return <span className="badge badge-pill badge-danger mt-2">Cancelado</span>
      case 'WAITING_FOR_APPROVAL':
        return <span className="badge badge-pill badge-warning mt-2">Aguardando aprovação</span>
      case 'WAITING_FOR_AUCTION':
        return <span className="badge badge-pill badge-warning mt-2">Aguardando Leilão</span>
      case 'IN_QUOTATION':
        return <span className="badge badge-pill badge-warning mt-2">Leilão em andamento</span>
      default:
        return <span className="badge badge-pill badge-secondary mt-2">Não identificado</span>
    }
  }

  if (!data.orders.length) {
    return <p>Voce não possui nenhum pedido entregue</p>
  }

  const paginate = (pageNumber) => setCurrentPage(pageNumber);


  return (
    <>
      <div className='row orders-table'>
        <div className='col-12'>
          <div className='row pt-5'>
            <div className='col-12'>
              <table className="table user-list">
                <thead>
                  <tr className='text-center'>
                    <th><span>Pedido Num</span></th>
                    <th><span>Categoria</span></th>
                    <th><span>Tipo do produto</span></th>
                    <th><span>Criado por</span></th>
                    <th><span>Criado em</span></th>
                    <th><span>Quantidade</span></th>
                    <th><span>Status</span></th>
                    <th><span>Lote</span></th>
                    <th><span>Fornecedor</span></th>
                    <th><span>Avaliação</span></th>
                  </tr>
                </thead>
                <tbody>
                  {data.orders.map(function (order, key) {
                    return (
                      <tr key={key} className='pt-3 text-center'>
                        <td>
                          <span>{order.num_order}</span>
                        </td>
                        <td>
                          {order.group}
                        </td>
                        <td>
                          {order.label}
                        </td>
                        <td>
                          {order.first_name} {order.lastName}
                        </td>
                        <td className="text-center">
                          {setDate(order.created_at)}
                        </td>
                        <td className='text-center'>
                          {order.quantity}
                        </td>
                        <td className='text-center'>
                          {setStatus(order.status)}
                        </td>
                        <td>
                          <Link to={`/payments/orders/${order.purchaser_id}?lote=${order.sale_date}`}><BsArrowUpRight size={16} /></Link>
                        </td>
                        <td>
                          <span>{order.fantasy_name}</span>
                        </td>
                        <td>
                          {order.score ?
                            [...Array(5)].map((item, index) => {
                              return (
                                <label>
                                  <div style={{ "cursor": "pointer" }}>
                                    <FaStar
                                      color={
                                        (index + 1) <= order.score
                                          ? "000"
                                          : "rgb(192,192,192)"
                                      }
                                    />
                                  </div>
                                </label>
                              );
                            })

                            :
                            <>
                              <button onClick={() => { setModalShow(true) }} className='btn btn-link text-decoration-none'>
                                Avalie seu pedido
                              </button>
                              <RatingModal
                                show={modalShow}
                                onHide={() => setModalShow(false)}
                                getOrders={handleRefresh}
                                order={order}
                              />
                            </>}
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {data.orders.map(function (order) {
        return (
          <div className='row orders-cards'>
            <div className='col-12'>
              <div className="card border shadow-lg my-3">
                <div className="card-body">
                  <div className='row py-3'>
                    <div className='col-4'>
                      <p className='text text-white h3'>{order.num_order}</p>
                    </div>
                    <div className='col-8 text-right'>
                      {setStatus(order.status)}
                    </div>
                  </div>
                  <div className='row py-3 text-center'>
                    <div className='row'>
                      <div className='col-6'>
                        <small>
                          <p className='p-0 m-0'><strong>Criado por: </strong></p>
                          <p className="p-0 m-0">{order.first_name} {order.last_name}</p>
                        </small>
                      </div>
                      <div className='col-6'>
                        <small>
                          <p className='p-0 m-0'><strong>Pedido: </strong></p>
                          <p className="p-0 m-0"><strong>{order.group}</strong> {order.label}</p>
                        </small>
                      </div>
                    </div>
                  </div>
                  <div className='row text-center'>
                    <div className='col-6'>
                      <small>
                        <p className='p-0 m-0'><strong>Quantidade: </strong></p>
                        <p className="p-0 m-0">{order.quantity}</p>
                      </small>
                    </div>
                    <div className='col-6'>
                      <small>
                        <p className='p-0 m-0'><strong>Prazo de Entrega: </strong></p>
                        <p className='p-0 m-0'>{setDate(order.deadline)}</p>
                      </small>
                    </div>
                  </div>
                  <div className='row pt-1 text-center'>
                    <div className='row '>
                      <div className='col-12 text-center'>
                        {order.score ?
                          [...Array(5)].map((item, index) => {
                            return (
                              <label>
                                <div style={{ "cursor": "pointer" }}>
                                  <FaStar
                                    color={
                                      (index + 1) <= order.score
                                        ? "000"
                                        : "rgb(192,192,192)"
                                    }
                                  />
                                </div>
                              </label>
                            );
                          })

                          :
                          <>
                            <button onClick={() => { setModalShow(true) }} className='btn btn-link text-decoration-none'>
                              Avalie seu pedido
                            </button>
                            <RatingModal
                              show={modalShow}
                              onHide={() => setModalShow(false)}
                              getOrders={handleRefresh}
                              order={order}
                            />
                          </>}
                      </div>
                    </div>
                  </div>
                  <div className='row pt-1'>
                    <div className='col-12 text-center'>
                      <Link to={`/payments/orders/${order.purchaser_id}?lote=${order.sale_date}`} className='btn btn-green'>Ver Lote <BsArrowUpRight size={16} /></Link>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      })}
      <ItensPagination ordersPerPage={orderPerPage} totalOrders={data.total} paginate={paginate} currentPage={currentPage} />
    </>
  )
}
export default ListDelivered;