import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Trash from '../../assets/trash.svg';


function OrderTable({ orderData, setOrdersToCreate }) {

  function removeItem(id){
    setOrdersToCreate(orderData.filter(obj => obj.id !== id))
  }

  return (
    orderData.map(order => (
      <tr>
        {/* <th scope="row">{order.number}</th> */}
        <td>{order.id.split('-')[1]}</td>
        <td>{order.category.label}</td>
        <td>{order.product.label}</td>
        <td>{order.quantity}</td>
        <td>{order.paymentMethod.label}</td>
        <td><button onClick={() => removeItem(order.id)} className='btn p-0 text-center'><img alt='trashicon' src={Trash} width='20px' height='20px' /></button></td>
      </tr>
        )
      )
  );
}

export default OrderTable;