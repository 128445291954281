import React from 'react';

export default function AlertModal({ message, hasError }) {

  return (
    <>
      {
        hasError ?
          <div class="alert alert-danger alert-dismissible fade show text-center fixed-top" role="alert">
            { message }
          </div>
          :
          <div class="alert alert-success text-center fixed-top" role="alert">
            { message }
          </div>
      }
    </>
  );
}