export function timer() {
    // criar um objeto Date com a data e hora atuais
    var agora = new Date();
    var diaDaSemana = agora.getDay();
    var milissegundosAteSegunda = ((8 - diaDaSemana) % 7) * 24 * 60 * 60 * 1000;
    var proximaSegunda = new Date(agora.getTime() + milissegundosAteSegunda);
    proximaSegunda.setHours(12, 0, 0, 0);
    var milissegundosRestantes = proximaSegunda.getTime() - agora.getTime();
    var segundosRestantes = Math.floor(milissegundosRestantes / 1000);
    var minutosRestantes = Math.floor(segundosRestantes / 60);
    var horasRestantes = Math.floor(minutosRestantes / 60);
    var diasRestantes = Math.floor(horasRestantes / 24);
    horasRestantes %= 24;
    minutosRestantes %= 60;
    segundosRestantes %= 60;
    return (diasRestantes + "d " + horasRestantes + "h " + minutosRestantes + "m " + segundosRestantes + "s");
}


export function getNextMonday(date = new Date()) {
    const dateCopy = new Date(date.getTime());

    const nextMonday = new Date(
        dateCopy.setDate(
            dateCopy.getDate() + ((7 - dateCopy.getDay() + 1) % 7 || 7),
        ),
    );

    return nextMonday;
}


export function getNextThursday(date = new Date()) {
    const dateCopy = new Date(date.getTime());

    const nextThursday = new Date(
        dateCopy.setDate(
            dateCopy.getDate() + ((3 - dateCopy.getDay() + 1) % 7 || 7),
        ),
    );

    return nextThursday;
}