import React, { useState, useContext } from 'react';
import { AuthContext } from '../../providers/auth'
import 'bootstrap/dist/css/bootstrap.min.css';
import FillSidebar from '../components/sidebar';
import Edit from '../../assets/edit.svg';
import Trash from '../../assets/trash.svg';
import NewUser from '../../assets/add_user.svg';
import '../../utils/css/global.css'
import axios from '../../services/axios';
import NewUserModal from './new-user';
import { Sidebar } from 'primereact/sidebar';
import { GiHamburgerMenu } from 'react-icons/gi';
import { useQuery, useMutation } from 'react-query';
import ItensPagination from '../components/pagination';
import Skeleton from 'react-loading-skeleton';
import AlertModal from '../components/alert-modal';

export default function Users() {
    const [modalShow, setModalShow] = useState(false);
    const [orderPerPage] = useState(4);
    const [currentPage, setCurrentPage] = useState(1)
    const [editModal, setEditModal] = useState(null)
    const [visibleLeft, setVisibleLeft] = useState(false);
    const { user } = useContext(AuthContext)

    const { data: users, isLoading: usersLoading, isError: usersError, refetch: refetchUsers } = useQuery('users', () =>
        axios.get(`/api/v1/accounts/users/company/${user.companyId}`)
            .then(response => response.data)
    );

    const deleteUserMutation = useMutation((id) =>
        axios.delete(`/api/v1/accounts/users/${id}`)
            .then(() => handleRefresh())
    );

    const handleDeleteUser = (id) => {
        deleteUserMutation.mutate(id);
    };

    if (usersLoading || deleteUserMutation.isLoading) {
        return <div>
            <div className='container-fluid'>
                <div className="row">
                    <div className='col-md-3 col-lg-2 col-2 p-0'>
                        <div className="card menubtn">
                            <button onClick={() => setVisibleLeft(true)} className="btn btn-outline text-center" >
                                <GiHamburgerMenu size={26} />
                            </button>
                            <Sidebar visible={visibleLeft} onHide={() => setVisibleLeft(false)}>
                                <FillSidebar user={user} />
                            </Sidebar>
                        </div>

                        <div className='side-list'>
                            <FillSidebar user={user} />
                        </div>
                    </div>
                    <div className='col-md-8 offset-md-1 pl-md-5 col-lg-9 offset-lg-1 col-xl-8 offset-xl-1 pr-md-5 pt-5'>
                        <div className='row'>
                            <Skeleton count={1} height={180} />
                        </div>
                        <div className='row mt-5'>
                            <Skeleton count={1} height={300} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }

    function setDate(data) {
        return new Date(data).toLocaleDateString();
    }

    const handleRefresh = () => {
        refetchUsers();
    };


    function reloadTableUsers() {
        setEditModal(null)
        handleRefresh()
    }

    function openEditPage(id) {
        setEditModal(id)
        setModalShow(true)
    }

    function setStatus(status) {
        switch (status) {
            case 'ACTIVE':
                return <span className="badge badge-pill badge-success mt-2">Ativo</span>
            case 'PENDING':
                return <span className="badge badge-pill badge-warning mt-2">Pendente</span>
            case 'INACTIVE':
                return <span className="badge badge-pill badge-danger mt-2">Inativo</span>
            default:
                return <span className="badge badge-pill badge-secondary mt-2">Não identificado</span>
        }
    }

    function firstChar(name) {
        return name.substring(0, 1)
    }


    return (
        <div className='container-fluid'>
            {
                usersError &&
                <AlertModal
                    message={'Error fetching data'}
                    hasError={true}
                />
            }
            {
                deleteUserMutation.isError &&
                <AlertModal
                    message={'Error deleting user'}
                    hasError={true}
                />
            }
            <div className="row">
                <div className='col-md-3 col-lg-2 col-2 p-0'>
                    <div className="card menubtn">
                        <button onClick={() => setVisibleLeft(true)} className="btn btn-outline text-center" >
                            <GiHamburgerMenu size={26} />
                        </button>
                        <Sidebar visible={visibleLeft} onHide={() => setVisibleLeft(false)}>
                            <FillSidebar user={user} />
                        </Sidebar>
                    </div>

                    <div className='side-list'>
                        <FillSidebar user={user} />
                    </div>
                </div>
                <div className='col-md-8 offset-md-1 col-lg-10 offset-lg-0 pr-md-5 pt-5'>
                    <div className='row border rounded shadow mb-5'>
                        <div className='col-12'>
                            <div className='row pt-3'>
                                <div className='col-12 '>
                                    <p className='h2 text-center py-3'>Pagina de usuarios</p>
                                    <p className='pt-3 pl-3 text-secondary'>
                                        Você possui {users?.length} usuario(s) vinculado(s) a <strong>{user.firstName} {user.last_name}</strong>
                                        , aqui é possivel editar, criar e excluir um usuario.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row pb-3'>
                        <div className='col-md-12 text-right'>
                            <button onClick={() => { setEditModal(null); setModalShow(true) }} className='btn btn-light border'>
                                <img src={NewUser} width='30px' height='30px' alt='newusericon' />
                            </button>
                            <NewUserModal
                                user={user}
                                show={modalShow}
                                reloadTableUsers={reloadTableUsers}
                                userEdit={editModal}
                                onHide={() => setModalShow(false)}
                            />
                        </div>
                    </div>
                    <div className="row users-table">
                        <div className="col-md-12">
                            <div className="main-box clearfix">
                                <div className="table-responsive">
                                    <table className="table user-list">
                                        <thead>
                                            <tr>
                                                <th><span>Usuario</span></th>
                                                <th><span>Criado em</span></th>
                                                <th className="text-center"><span>Status</span></th>
                                                <th><span>Email</span></th>
                                                <th>&nbsp;</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {users?.slice((currentPage - 1) * orderPerPage, currentPage * orderPerPage).map(function (profile, key) {
                                                return (<tr key={key} className='pt-3'>
                                                    <td>
                                                        <div className='row'>
                                                            <div className='col-2 text-center'>
                                                                <div className='circle'>
                                                                    <p className='text text-white h3'>{firstChar(profile.first_name)}</p>
                                                                </div>

                                                            </div>
                                                            <div className='col-10'>
                                                                <span className="user-link font-weight-bold m-0">{profile.first_name} {profile.last_name}</span>
                                                                <span className="user-subhead">{profile.role}</span>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        {setDate(profile.created_at)}
                                                    </td>
                                                    <td className="text-center">
                                                        {setStatus(profile.status)}
                                                    </td>
                                                    <td>
                                                        <span className="font-weight-bold">{profile.email}</span>
                                                    </td>
                                                    <td className='text-right'>
                                                        <span className="text-right">
                                                            <button className='btn p-0 mr-2' onClick={() => openEditPage(profile.id)}><img alt='editicon' src={Edit} width='20px' height='20px' /></button>
                                                            <button className='btn p-0' onClick={() => handleDeleteUser(profile.id)}><img alt='trahsicon' src={Trash} width='20px' height='20px' /></button>
                                                        </span>
                                                    </td>
                                                </tr>)
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            {users?.length ?
                                <ItensPagination ordersPerPage={orderPerPage} totalOrders={users?.length} paginate={currentPage => setCurrentPage(currentPage)} currentPage={currentPage} />
                                :
                                <></>
                            }
                        </div>
                    </div>
                    {users?.map(function (profile, key) {
                        return (
                            <div key={profile.id} className='row users-cards'>
                                <div className='col-sm-12'>
                                    <div className="card border shadow-lg my-3">
                                        <div className="card-body">
                                            <div className='row py-3'>
                                                <div className='col-1'>
                                                    <div className='circle'>
                                                        <p className='text text-white h3'>{firstChar(profile.first_name)}</p>
                                                    </div>
                                                </div>
                                                <div className='col-11 text-right mt-1'>
                                                    {setStatus(profile.status)}
                                                </div>
                                            </div>
                                            <div className='row py-3 text-center'>
                                                <div className='row'>
                                                    <div className='col-12'>
                                                        <span className="user-link font-weight-bold m-0">{profile.first_name} {profile.last_name}</span>
                                                        <br></br>
                                                        <small>
                                                            <span className="user-subhead">{profile.role}</span>
                                                        </small>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row text-center'>
                                                <div className='col-6'>
                                                    <small>
                                                        <p className='p-0 m-0'><strong>Criado em: </strong></p>
                                                        {setDate(profile.created_at)}
                                                    </small>
                                                </div>
                                                <div className='col-6'>
                                                    <small>
                                                        <p className='p-0 m-0'><strong>Email: </strong></p>
                                                        <p className='p-0 m-0'>{profile.email}</p>
                                                    </small>
                                                </div>
                                            </div>
                                            <div className='row pt-4'>
                                                <div className='col-6 d-flex justify-content-start'>
                                                    <span className="text-left">
                                                        <button className='btn p-0 mr-2' onClick={() => openEditPage(profile.id)}><img alt='editicon' src={Edit} width='20px' height='20px' /></button>
                                                    </span>
                                                </div>
                                                <div className='col-6 d-flex justify-content-end'>
                                                    <span className="text-right">
                                                        <button className='btn p-0' onClick={() => handleDeleteUser(profile.id)}><img alt='trahsicon' src={Trash} width='20px' height='20px' /></button>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    );
}