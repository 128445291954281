import React, { useState, useContext } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import ItensPagination from '../components/pagination';
import axios from '../../services/axios';
import CloseButton from 'react-bootstrap/CloseButton';
import { AuthContext } from '../../providers/auth'
import { useQuery, useMutation } from 'react-query';
import { NotifyContext } from '../contexts/NotifyContext';
import { toast } from 'react-toastify';


function NotReadNotify() {
  const [orderPerPage] = useState(9);
  const { currentPage, setCurrentPage } = useContext(NotifyContext); // Importe o NotifyContext
  const { user } = useContext(AuthContext);

  const { data: notReadNotifyData, isLoading, refetch } = useQuery(
    ['notReadNotifications', user.id, currentPage, orderPerPage],
    () =>
      axios
        .get(`/api/v1/notifications/${user.id}?status=UNREAD&page=${currentPage}&limit=${orderPerPage}`)
        .then(response => response.data)
        .catch((error) => {
          toast.error(error.message)
        })
  );

  const readNotificationMutation = useMutation((notificationId) =>
    axios.post(`/api/v1/notifications/${notificationId}`)
  );

  const readNotification = (notificationId) => {
    readNotificationMutation.mutate(notificationId, {
      onSuccess: () => {
        if (currentPage > 1 && Number((notReadNotifyData.total - 1) / orderPerPage) <= currentPage - 1) {
          setCurrentPage((prevPage) => prevPage - 1);
        } else {
          handleRefresh();
        }
      },
      onError: (error) => {
        console.log(error);
      },
    });
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const handleRefresh = () => {
    refetch();
  };

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  if (!notReadNotifyData?.notifications?.length) {
    return <p className="text-center font-weight-bold h4 pt-5">Você não possui nenhuma notificação</p>;
  }

  return (
    <div className='container'>
      <div className='row pt-3'>
        {
          notReadNotifyData?.notifications?.map(notificacao => (
            <div className='col-md-4 col-sm-12 py-3'  key={notificacao.id}>
              <div className="card h-100 border-dark shadow">
                <div className="card-header">
                  <div className='row notify-desk'>
                    <div className='col-11 text-center'>
                      <small><span><strong>{notificacao.title}</strong></span></small>
                    </div>
                    <div className='col-1 d-flex align-items-center justify-content-end'>
                      <CloseButton onClick={() => readNotification(notificacao.id)} />
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className='row'>
                    <div className='col-12'>
                      <span>
                        {notificacao.detail}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))
        }
      </div>

      <ItensPagination ordersPerPage={orderPerPage} totalOrders={notReadNotifyData.total} paginate={paginate} currentPage={currentPage} />
    </div>
  );
}

export default NotReadNotify;