import React, { useState,  useContext } from 'react';
import { AuthContext } from '../../providers/auth'
import 'bootstrap/dist/css/bootstrap.min.css';
import FillSidebar from '../components/sidebar';
import IconNotas from '../../assets/icon_nota_fiscais.svg';
import '../../utils/css/global.css'
import axios from '../../services/axios';
import { Sidebar } from 'primereact/sidebar';
import { GiHamburgerMenu } from 'react-icons/gi';
import ItensPagination from '../components/pagination';
import { toast } from 'react-toastify';
import { useQuery } from 'react-query';

function OrdersPerCompany() {
    const [orderPerPage] = useState(5);
    const [currentPage, setCurrentPage] = useState(1)
    const { user } = useContext(AuthContext);
    const [visibleLeft, setVisibleLeft] = useState(false);

    function getCompanyId() {
        const url = window.location.pathname;
        const urlParts = url.split('/');
        return urlParts[urlParts.length - 1];
    }

    const { data, isLoading, isError } = useQuery('getLots', async () => {
        const urlParams = new URLSearchParams(window.location.search);
        const lote = urlParams.get('lote');

        const response = await axios.get(`/api/v1/lots/orders/${user.companyId}`, {
            params: { date: lote, purchaserId: getCompanyId() }
        }).catch((error) => {
            toast.error(error.message)
        })
        return response.data;
    });

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (isError) {
        return <div>Error fetching data</div>;
    }


    function getLote() {
        const urlParams = new URLSearchParams(window.location.search);
        return new Date(urlParams.get('lote')).toLocaleDateString()
    }

    function setStatus(status) {
        switch (status) {
            case 'AWAITING_PAYMENT':
                return <span className="badge badge-pill badge-warning mt-2">Aguardando pagamento</span>
            case 'AWAITING_DELIVERY':
                return <span className="badge badge-pill badge-warning mt-2">Aguardando Entrega</span>
            case 'DELIVERED':
                return <span className="badge badge-pill badge-success mt-2">Entregue</span>
            case 'OUT_FOR_DELIVERY':
                return <span className="badge badge-pill badge-success mt-2">Saiu para Entrega</span>
            case 'LATE_ORDER':
                return <span className="badge badge-pill badge-danger mt-2">Pedido Atrasado</span>
            case 'ORDER_CANCELED':
                return <span className="badge badge-pill badge-danger mt-2">Cancelado</span>
            case 'WAITING_FOR_APPROVAL':
                return <span className="badge badge-pill badge-warning mt-2">Aguardando aprovação</span>
            case 'WAITING_FOR_AUCTION':
                return <span className="badge badge-pill badge-warning mt-2">Aguardando Leilão</span>
            case 'IN_QUOTATION':
                return <span className="badge badge-pill badge-warning mt-2">Leilão em andamento</span>
            case 'ACTIVE':
                return <span className="badge badge-pill badge-warning mt-2">Em Leilão</span>
            default:
                return <span className="badge badge-pill badge-secondary mt-2">Não identificado</span>
        }
    }

    if (data?.orders?.total === 0 || !data?.orders) {
        return <p>Voce não possui nenhum pedido</p>
    }

    function getNFe(productID) {
        try {
            window.open(`${process.env.REACT_APP_API_URL}/api/v1/nfe/getNFe/${productID}`, '_blank', "noopener,noreferrer")
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <div className='container-fluid'>
            <div className="row">
                <div className='col-md-3 col-lg-2 col-2 p-0'>
                    <div className="card menubtn">
                        <button onClick={() => setVisibleLeft(true)} className="btn btn-outline text-center" >
                            <GiHamburgerMenu size={26} />
                        </button>
                        <Sidebar visible={visibleLeft} onHide={() => setVisibleLeft(false)}>
                            <FillSidebar user={user} />
                        </Sidebar>
                    </div>

                    <div className='side-list' >
                        <FillSidebar user={user} />
                    </div>
                </div>
                <div className='col-md-8 offset-md-1 col-lg-8 col-xl-10 offset-xl-0 offset-lg-2 pl-xl-4 pl-md-4 pl-lg-0 pl-xl-4 pr-md-5 pt-4'>
                    <>
                        <div className='row border rounded shadow mb-3'>
                            <div className='col-12'>
                                <div className='row pt-3'>
                                    <div className='col-12'>
                                        <p className='h3'>Bem vindo, {user.firstName}</p>
                                        <p className=''>Pedidos do lote {getLote()}</p>
                                    </div>
                                </div>
                                <div className='row py-4'>
                                    <div className='col-12'>
                                        <p>Olá,
                                            Está pagina é para visualização do lote {getLote()}, um lote é um conjunto de leilões em que um pedido criado por voce estava neste leilão.<br />
                                            O nome do lote é a data em quem ocorreu o leilão.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            data.lots && data.lots.length > 0 ?
                                <div className='row mb-5'>
                                    <div className='col-md-4 col-sm-12 py-2 text-center '>
                                        <div className='card border-left-primary shadow h-100'>
                                            <p className='h5 my-2'>Valor do lote</p>
                                            <p>R${data.lots[0].price}</p>
                                        </div>
                                    </div>
                                    <div className='col-md-4 col-sm-12 py-2 text-center'>
                                        <div className='card border-left-warning shadow h-100'>
                                            {   data.lots[0].boleto_status == 'GENERATED' ?
                                                    <>                                            
                                                        <p className='h5 my-2'>Boleto</p>
                                                        <a href={data.lots[0].boleto_link} className='btn btn-light'>
                                                            <img alt='notasIcon' width='20px' height='20px' src={IconNotas} />
                                                            <span className='ml-2' style={{ "font-size": "12.25px" }}>Visualizar o boleto</span>
                                                        </a>
                                                    </>
                                                :
                                                    <>
                                                        <p className='h5 my-2'>Boleto</p>
                                                        <button className='btn btn-light' disabled>
                                                            <img alt='notasIcon' width='20px' height='20px' src={IconNotas} />
                                                            <span className='ml-2' style={{ "font-size": "12.25px" }}>Boleto pago com sucesso</span>
                                                        </button>
                                                    </>
                                            }

                                        </div>
                                    </div>
                                    <div className='col-md-4 col-sm-12 py-2 text-center'>
                                        <div className='card border-left-success shadow h-100'>
                                            {
                                                data.lots[0].NFe ?
                                                    <>
                                                        <p className='h5 my-2'>Nota Fiscal</p>
                                                        <button onClick={() => getNFe(data.lots[0].id)} className='btn btn-light'>
                                                            <img alt='notasIcon' width='20px' height='20px' src={IconNotas} />
                                                            <span className='ml-2' style={{ "font-size": "12.25px" }}>Nota Fiscal</span>
                                                        </button>
                                                    </>
                                                    :
                                                    <>
                                                        <p className='h5 my-2'>Nota Fiscal</p>
                                                        <button onClick={() => getNFe(data.lots[0].id)} className='btn btn-light' disabled>
                                                            <img alt='notasIcon' width='20px' height='20px' src={IconNotas} />
                                                            <span className='ml-2' style={{ "font-size": "12.25px" }}>Ainda não possui Nota Fiscal</span>
                                                        </button>
                                                    </>
                                            }
                                        </div>
                                    </div>
                                </div>
                                :
                                <></>
                        }
                        <div className='row'>
                            <div className='col-12'>
                                <p>Os pedidos desse lote estão listado abaixo:</p>
                            </div>
                        </div>
                        <div className='row orders-table'>
                            <div className='col-12'>
                                <table className="table user-list">
                                    <thead>
                                        <tr className='text-center'>
                                            <th><span>Pedido Num</span></th>
                                            <th><span>Categoria</span></th>
                                            <th><span>Tipo do produto</span></th>
                                            <th><span>Quantidade</span></th>
                                            <th><span>Status</span></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.orders.slice((currentPage - 1) * orderPerPage, currentPage * orderPerPage).map(function (order, key) {
                                            return (
                                                <tr key={key} className='pt-3 text-center'>
                                                    <td>
                                                        <span>{order.num_order}</span>
                                                    </td>
                                                    <td>
                                                        {order.group}
                                                    </td>
                                                    <td>
                                                        {order.label}
                                                    </td>
                                                    <td className='text-center'>
                                                        {order.quantity}
                                                    </td>
                                                    <td>
                                                        {setStatus(order.status)}
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        {data.orders.length ?
                            <ItensPagination ordersPerPage={orderPerPage} totalOrders={data.orders.length} paginate={currentPage => setCurrentPage(currentPage)} currentPage={currentPage} />
                            :
                            <></>
                        }
                        {data.orders.map(function (order) {
                            return (
                                <div className='row orders-cards'>
                                    <div className='col-12'>
                                        <div className="card border shadow-lg my-3">
                                            <div className="card-body">
                                                <div className='row py-3'>
                                                    <div className='col-4'>
                                                        <p className='text text-white h3'>{order.num_order}</p>
                                                    </div>
                                                    <div className='col-8 text-right'>
                                                        {setStatus(order.status)}
                                                    </div>
                                                </div>
                                                <div className='row py-3 text-center'>
                                                    <div className='row'>
                                                        <div className='col-6'>
                                                            <small>
                                                                <p className='p-0 m-0'><strong>Pedido: </strong></p>
                                                                <p className="p-0 m-0"><strong>{order.group}</strong> {order.label}</p>
                                                            </small>
                                                        </div>
                                                        <div className='col-6'>
                                                            <small>
                                                                <p className='p-0 m-0'><strong>Quantidade: </strong></p>
                                                                <p className="p-0 m-0">{order.quantity}</p>
                                                            </small>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='row pt-2'>
                                                    <div className='col-12 text-center'>
                                                        {order.status === 'AWAITING_PAYMENT' ?
                                                            <>
                                                                <button className='btn btn-light' onClick={() => console.log("MANUTENÇÃO")}>
                                                                    <img alt='notasIcon' width='20px' height='20px' src={IconNotas} />
                                                                    <span className='ml-2' style={{ "font-size": "12.25px" }}>Visualizar o boleto</span>
                                                                </button>
                                                            </>
                                                            :
                                                            <span>
                                                                {order.NFe ?
                                                                    <button className='btn btn-light' onClick={() => getNFe(order.id)}>
                                                                        <img alt='anexaricon' width='20px' height='20px' src={IconNotas} />
                                                                        <span className='ml-2' style={{ "font-size": "12.25px" }}>Visualizar a Nota fiscal</span>
                                                                    </button>
                                                                    :
                                                                    <>
                                                                        <img alt='notasIcon' width='20px' height='20px' src={IconNotas} />
                                                                        <p className='p-0 m-0'><small>O pedido ainda não possui nota fiscal</small></p>
                                                                    </>
                                                                }
                                                            </span>
                                                        }
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}

                    </>
                </div>
            </div>
        </div>
    );
}

export default OrdersPerCompany;