import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../../providers/auth'
import 'bootstrap/dist/css/bootstrap.min.css';
import FillSidebar from '../components/sidebar';
import axios from '../../services/axios';
import Ok from '../../assets/status_ok.svg';
import Cancel from '../../assets/status_cancel.svg';
import Waiting from '../../assets/status_waiting.svg';
import { Sidebar } from 'primereact/sidebar';
import { GiHamburgerMenu } from 'react-icons/gi';
import { toast } from 'react-toastify';

function Home() {
  // const [orders, setOrders] = useState([])
  const [dashData, setDashData] = useState(
    {
      lastOrders: [],
      ordersCanceled: 0,
      ordersDelivered: 0,
      ordersDelivering: 0
    }
  )
  const { user } = useContext(AuthContext);
  const [visibleLeft, setVisibleLeft] = useState(false);


  useEffect(() => {
    getDashOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function getDashOrders() {
    if (user.companyId) {
      axios.get(`/api/v1/orders/dashboard/${user.companyId}`).then(response => {
        setDashData(response.data)
      }).catch((error) => {
        toast.error(error.message)
      })
    }
  }

  return (
    <div className='container-fluid'>
      <div className="row">
        <div className='col-md-3 col-lg-2 col-2 p-0'>
          <div className="card menubtn">
            <button onClick={() => setVisibleLeft(true)} className="btn btn-outline text-center" >
              <GiHamburgerMenu size={26} />
            </button>
            <Sidebar visible={visibleLeft} onHide={() => setVisibleLeft(false)}>
              <FillSidebar user={user} />
            </Sidebar>
          </div>

          <div className='side-list'>
            <FillSidebar user={user} />
          </div>
        </div>
        <div className='col-md-8 offset-md-1 col-lg-10 offset-lg-0 pl-2 pr-5 pt-5'>
          <div className='row ml-2 border rounded shadow mb-5'>
            <div className='col-12'>
              <div className='row pt-3'>
                <div className='col-12'>
                  <p className='h3'>Bem vindo, {user.firstName}</p>
                  <p className=''>Resumo das suas atividades dentro da plataforma Fillstock.</p>
                </div>
              </div>
              <div className='row py-4 text-center'>
                <div className='col-4 '>
                  <img width='24px' height='24px' src={Ok} alt='Status Ok' />
                  <span className='text-success pl-2'>{dashData.ordersDelivered || 0} pedidos Efetivado</span>
                </div>
                <div className='col-4'>
                  <img width='24px' height='24px' src={Waiting} alt='Status Aguardando' />
                  <span className='text-warning pl-2'>{dashData.ordersDelivering || 0} pedidos A caminho</span>
                </div>
                <div className='col-4'>
                  <img width='24px' height='24px' src={Cancel} alt='Status Cancelados' />
                  <span className='text-danger pl-2'>{dashData.ordersCanceled || 0} pedidos Cancelados</span>
                </div>
              </div>
            </div>
          </div>
          <div className='row mb-5'>
            <div className='col-md-4 col-sm-12 py-2 text-center'>
              <div className='card border-left-warning shadow h-100'>
                <p className='h5 my-2'>Faturamento do mês</p>
                <p>R$2.357.265,99</p>
              </div>
            </div>
            <div className='col-md-4 col-sm-12 py-2 text-center '>
              <div className='card border-left-primary shadow h-100'>
                <p className='h5 my-2'>Faturamento do ultimo mês</p>
                <p>R$2.357.265,99</p>
              </div>
            </div>
            <div className='col-md-4 col-sm-12 py-2 text-center'>
              <div className='card border-left-success shadow h-100'>
                <p className='h5 my-2'>Qtd de Pedidos aguardando resposta</p>
                <p>8</p>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-12'>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;