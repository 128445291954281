import '../App.css';
import React, { useContext } from 'react';
import { AuthContext } from '../providers/auth'
import SignUpProvider from '../pages/sign_up/sign_up_provider';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import MyData from '../pages/my-data/my-data';

// Client
import HomeClient from '../pages/home/home_client';
import MyOrdersClient from '../pages/orders/my_orders';
import CreateOrders from '../pages/orders/create_order'
import Users from '../pages/users/users';
import Approve from '../pages/approve/approve';
import LoginClient from '../pages/sign_in/sign-in_client'
import Listagem from '../pages/leilao/list'
//

// Provider
import Home from '../pages/home/home_company';
import MyOrders from '../pages/sales/provider/my_orders_companys';
import OrderDetail from '../pages/sales/provider/order_company';
import List from '../pages/leilao/list';
import Notify from '../pages/notify/notify';
import Payments from '../pages/payments/list';
import LotesPerCompany from '../pages/payments/list_lotes';
import OrdersPerCompany from '../pages/payments/list_orders';
import SalesToProvider from '../pages/sales/provider/my_sales';
//

const PublicRoutes = () => {
    const { user } = useContext(AuthContext)

    if (user && user.kind === 'PROVIDER') {
        return (
            <BrowserRouter>
                <Routes>
                    <Route
                        path="/login"
                        element={user ? <Navigate to="/" /> : <LoginClient />}
                    />
                    <Route
                        path="/list"
                        element={user ? <List /> : <Navigate to="/login" />}
                    />
                    <Route
                        path="/"
                        element={user ? <Home /> : <Navigate to="/login" />}
                    />
                    <Route
                        path="/my-data"
                        element={user ? <MyData /> : <Navigate to="/login" />}
                    />
                    <Route
                        path="/sales"
                        element={user ? <SalesToProvider /> : <Navigate to="/login" />}
                    />
                    <Route
                        path="/sales/lots/:id"
                        element={user ? <MyOrders /> : <Navigate to="/login" />}
                    />
                    <Route
                        path="/lots/orders/:id"
                        element={user ? <OrderDetail /> : <Navigate to="/login" />}
                    />
                    <Route
                        path="/add/order"
                        element={user ? <CreateOrders /> : <Navigate to="/login" />}
                    />
                    <Route
                        path="/users"
                        element={user ? <Users /> : <Navigate to="/login" />}
                    />
                    <Route
                        path="/approve"
                        element={user ? <Approve /> : <Navigate to="/login" />}
                    />
                    <Route
                        path="/list"
                        element={user ? <Listagem /> : <Navigate to="/login" />}
                    />
                    <Route
                        path="/notify"
                        element={user ? <Notify user={user} /> : <Navigate to="/login" />}
                    />
                </Routes>
            </BrowserRouter>
        )
    }
    if (user && user.kind === 'CLIENT') {
        return (
            <BrowserRouter>
                <Routes>
                    <Route
                        path="/login"
                        element={user ? <Navigate to="/" /> : <LoginClient />}
                    />
                    <Route
                        path="/"
                        element={user ? <HomeClient /> : <Navigate to="/login" />}
                    />
                    <Route
                        path="/my-data"
                        element={user ? <MyData /> : <Navigate to="/login" />}
                    />
                    <Route
                        path="/orders"
                        element={user ? <MyOrdersClient /> : <Navigate to="/login" />}
                    />
                    <Route
                        path="/add/order"
                        element={user ? <CreateOrders /> : <Navigate to="/login" />}
                    />
                    <Route
                        path="/payments"
                        element={user ? <Payments /> : <Navigate to="/login" />}
                    />
                    <Route
                        path="/payments/lotes/:id"
                        element={user ? <LotesPerCompany /> : <Navigate to="/login" />}
                    />
                    <Route
                        path="/payments/orders/:id"
                        element={user ? <OrdersPerCompany /> : <Navigate to="/login" />}
                    />
                    <Route
                        path="/users"
                        element={user ? <Users /> : <Navigate to="/login" />}
                    />
                    <Route
                        path="/approve"
                        element={user ? <Approve /> : <Navigate to="/login" />}
                    />
                    <Route
                        path="/list"
                        element={user ? <Listagem /> : <Navigate to="/login" />}
                    />
                    <Route
                        path="/notify"
                        element={user ? <Notify user={user} /> : <Navigate to="/login" />}
                    />
                </Routes>
            </BrowserRouter>
        )
    }

    return (
        <BrowserRouter>
            <Routes>
                <Route
                    path="/"
                    element={user ? (<Home />) : <Navigate to="/login" />}
                />
                <Route
                    path="/login"
                    element={user ? <Navigate to="/" /> : <LoginClient />}
                />
                <Route
                    path="/sign-up"
                    element={<SignUpProvider />}
                />
            </Routes>
        </BrowserRouter>
    );
};

export default PublicRoutes;
