import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Pagination from "react-bootstrap/Pagination";

const ItensPagination = ({ordersPerPage, totalOrders, paginate, currentPage}) => {

    const pageNumbers = [];

    for(let i = 1; i <= Math.ceil(totalOrders / ordersPerPage); i++ ) {
        pageNumbers.push(i);
    }

    const createPaginationItem = (i) => {
        return <Pagination.Item
            key={i}
            active={i === currentPage}
            onClick={() => paginate(i)}
          >
            {i}
          </Pagination.Item>
      };

    return (
        <div className='row mt-5'>
            <div className='col-12'>
                <nav aria-label="Navegação de página exemplo">
                    <ul className="pagination d-flex justify-content-center">
                        <li className="page-item">
                            <button className="page-link" tabIndex="-1" disabled={currentPage <= 1} onClick={() => paginate(Number(currentPage - 1))}>Anterior</button>
                        </li>
                        {
                            pageNumbers.map(num => (
                                createPaginationItem(num)
                            ))
                        }
                        
                        <li className="page-item">
                            <button className="page-link" disabled={currentPage >= pageNumbers?.length} onClick={() => paginate(Number(currentPage + 1))}>Próximo</button>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    )
}

export default ItensPagination;