import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../utils/css/global.css'
import { Modal } from "react-bootstrap";
import { toast } from 'react-toastify';
import axios from '../../services/axios';


export default function DeleteOrder(props, item) {
      
    async function deleteOrder(orderId) {
       await axios.put(`/api/v1/orders/deleteOrder/${orderId}`).then(response => {
            props.onHide()
        }).then(() => {
            toast.success("Pedido deletado!")
        }).catch((error) => {
            toast.error(error.message)
        })
    }

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    <h2 className="mb-3">AVISO</h2>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='row py-3'>
                    <div className='col-12'>
                        <p className='h5 text-center'>
                            Tem certeza que deseja excluir definitivamente o pedido de categoria {props.item.group} e tipo {props.item.label}?
                            após a exclusão não é possivel recuperar o mesmo.
                        </p>
                    </div>
                </div>
                <div className='row pt-4 pb-2'>
                    <div className='col-6 d-flex justify-content-start'>
                        <button onClick={props.onHide} className="btn btn-outline-success">Fechar</button>
                    </div>
                    <div className='col-6 d-flex justify-content-end'>
                        <button onClick={() => deleteOrder(props.item.id)} className="btn btn-success">Enviar</button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}
