import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../utils/css/global.css'
import axios from '../../services/axios';
import { toast } from 'react-toastify';
import Select from 'react-select';

function SignUpProvider() {
  const navigate = useNavigate()
  const [data, setData] = useState({});
  const [bank, setBank] = useState([]);
  const [term, setTerm] = useState(false);
  const options = [
    { value: 'PROVIDER', label: 'Fornecedor', name: 'kind' },
    { value: 'CLIENT', label: 'Cliente', name: 'kind' }
  ]

  useEffect(() => {
    if (bank.length === 0) {
      setTimeout(() => {
        getBanks();
      }, 100);
    }
  }, [bank])

  async function getBanks() {
    await axios.get(`/api/v1/banks/`).then(response => {
      setTimeout(() => {
        setBank(response.data);
      }, 500);
    }).catch((error) => {
      toast.error(error.message)
    })
  }

  function handleChange(event) {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    })
  }

  function handleChangeEnum(event) {
    setData({
      ...data,
      [event.name]: event.value,
    })
  }

  async function checkCEP() {
    await fetch(`https://viacep.com.br/ws/${data.zipCode}/json/`, {
      method: 'GET'
    }).then(response => {
      return response.json();
    }).then(response => {
      setData({
        ...data,
        street: response.logradouro,
        city: response.localidade,
        neighborhood: response.bairro,
        uf: response.uf
      })
    });
  };

  function sendRequest(e) {
    e.preventDefault()
    if (data.pass !== data.passwordConfirmation) return alert("Senhas não correspondem")
    if (!term) return alert("Necessário concordar com nossos termos para continuar")
    axios.post('/api/v1/accounts', data).then(() => {
      navigate('/')
    })
  }

  return (
    <div className="container-fluid ">
      <div className='container'>
        <div className='row border shadow my-5'>
          <div className='col-md-10 offset-1'>
            <h1 className='text-center pt-4'><strong>CADASTRO</strong></h1>
            <form className='form-group' onSubmit={(sendRequest)}>
              <div className='row'>
                <div className='col-12'>
                  <p className='pt-4 h4'><strong>DADOS PROFISSIONAIS:</strong></p>
                </div>
              </div>
              <div className='row d-flex justify-content-center py-2'>
                <div className='col-6'>
                  <div className="form-group required">
                    <label htmlFor="fantasyName" className='control-label'><strong>Nome Fantasia:</strong></label>
                    <input type="text" className="form-control rounded mr-3" id="fantasyName" name="fantasyName"
                      placeholder="Nome Fantasia" onChange={(handleChange)} />
                  </div>
                </div>
                <div className='col-6'>
                  <div className="form-group required">
                    <label htmlFor="corporateName" className='control-label'><strong>Razão Social:</strong></label>
                    <input type="text" className="form-control rounded mr-3" id="corporateName" name="corporateName"
                      placeholder="Razão Social" onChange={(handleChange)} />
                  </div>
                </div>
              </div>
              <div className='row d-flex justify-content-center py-2'>
                <div className='col-6'>
                  <div className="form-group required">
                    <label htmlFor="cnpj" className='control-label'><strong>CNPJ:</strong></label>
                    <input type="text" className="form-control rounded mr-3" id="cnpj" name="cnpj"
                      placeholder="CNPJ" maxLength={18} onChange={(handleChange)} />
                  </div>
                </div>
                <div className='col-6'>
                  <label htmlFor="ufRegistration" className='control-label'><strong>Inscrição Estadual:</strong></label>
                  <input type="text" className="form-control rounded mr-3" id="ufRegistration" name="ufRegistration"
                    placeholder="Inscrição Estadual" maxLength={18} onChange={(handleChange)} />
                </div>
              </div>
              <div className='row d-flex justify-content-center py-2'>
                <div className='col-6'>
                  <label htmlFor="number" className='control-label'><strong>Tipo:</strong></label>
                  <Select className='rounded' placeholder='' options={options} name="kind" onChange={(handleChangeEnum)} />
                </div>
                <div className='col-6'>
                </div>
              </div>
              <div className='row'>
                <div className='col-12'>
                  <p className='pt-4 h4'><strong>DADOS PESSOAIS:</strong></p>
                </div>
              </div>
              <div className='row d-flex justify-content-center py-2'>
                <div className='col-6'>
                  <div className="form-group required">
                    <label htmlFor="email" className='control-label'><strong>Email:</strong></label>
                    <input type="text" className="form-control rounded mr-3" id="email" name="email"
                      placeholder="Email" onChange={(handleChange)} />
                  </div>
                </div>
                <div className='col-6'>
                  <div className="form-group required">
                    <label htmlFor="cpf" className='control-label'><strong>CPF:</strong></label>
                    <input type="text" className="form-control rounded mr-3" id="cpf" name="cpf"
                      placeholder="CPF" onChange={(handleChange)} />
                  </div>
                </div>
              </div>
              <div className='row d-flex justify-content-center py-2'>
                <div className='col-6'>
                  <div className="form-group required">
                    <label htmlFor="fname" className='control-label'><strong>Primeiro nome:</strong></label>
                    <input type="text" className="form-control rounded mr-3" id="firstName" name="firstName"
                      placeholder="Primeiro nome" onChange={(handleChange)} />
                  </div>
                </div>
                <div className='col-6'>
                  <div className="form-group required">
                    <label htmlFor="lname" className='control-label'><strong>Primeiro nome:</strong></label>
                    <input type="text" className="form-control rounded mr-3" id="lastName" name="lastName"
                      placeholder="Primeiro nome" onChange={(handleChange)} />
                  </div>
                </div>
              </div>
              <div className='row d-flex justify-content-center py-2'>
                <div className='col-6'>
                  <div className="form-group required">
                    <label htmlFor="password" className='control-label'><strong>Senha:</strong></label>
                    <input type="password" className="form-control rounded mr-3" id="password" name="pass"
                      placeholder="Digite sua senha..." onChange={(handleChange)} />
                  </div>
                </div>
                <div className='col-6'>
                  <div className="form-group required">
                    <label htmlFor="password1" className='control-label'><strong>Confirmação de senha:</strong></label>
                    <input type="password" className="form-control rounded mr-3" id="passwordConfirmation" name="passwordConfirmation"
                      placeholder="Confirme sua senha..." onChange={(handleChange)} />
                  </div>
                </div>
              </div>

              <div className='row'>
                <div className='col-12'>
                  <p className='pt-4 h4'><strong>DADOS BANCARIOS:</strong></p>
                </div>
              </div>
              <div className='row my-3'>
                <div className='col'>
                  <p className='control-label'><strong>Tipo da Conta:</strong></p>
                  <div className="form-check form-check-inline">
                    <input className="form-check-input mt-1" type="radio" name="inlineRadioOptions" id="contcorr" value="contcorr"/>
                    <label className="form-check-label" htmlFor="contcorr">Conta Corrente</label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input className="form-check-input mt-1" type="radio" name="inlineRadioOptions" id="contconj" value="contconj"/>
                    <label className="form-check-label" htmlFor="contconj">Conta Conjunta</label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input className="form-check-input mt-1" type="radio" name="inlineRadioOptions" id="contpoup" value="contpoup"/>
                    <label className="form-check-label" htmlFor="contpoup">Conta Poupança</label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input className="form-check-input mt-1" type="radio" name="inlineRadioOptions" id="cpoupconj" value="cpoupconj"/>
                    <label className="form-check-label" htmlFor="cpoupconj">Conta Poupança Conjunta</label>
                  </div>
                </div>
              </div>
              <div className='row d-flex justify-content-center py-2'>
                <div className='col-6'>
                  <div className="form-group required">
                    <label htmlFor="bank" className='control-label'><strong>Banco:</strong></label>
                    <Select className='rounded' placeholder='' options={bank} name="bank" onChange={(handleChangeEnum)} />
                  </div>
                </div>
                <div className='col-3'>
                  <div className="form-group required">
                    <label htmlFor="bank_agency" className='control-label'><strong>Agência:</strong></label>
                    <input type="text" className="form-control rounded mr-3" id="bank_agency" name="bank_agency"
                      placeholder="Agência bancaria" onChange={(handleChange)} />
                  </div>
                </div>
                <div className='col-3'>
                  <div className="form-group required">
                    <label htmlFor="digitobank_agency" className='control-label'><strong>Digito:</strong></label>
                    <input type="text" className="form-control rounded mr-3" id="digitobank_agency" name="digitobank_agency"
                      placeholder="Digito da Agência Bancaria" onChange={(handleChange)} />
                  </div>
                </div>
              </div>
              <div className='row d-flex justify-content-center py-2'>
                <div className='col-3'>
                  <div className="form-group required">
                    <label htmlFor="contacorrente" className='control-label'><strong>Conta corrente:</strong></label>
                    <input type="text" className="form-control rounded mr-3" id="contacorrente" name="contacorrente"
                      placeholder="Conta corrente (somente numeros)" onChange={(handleChange)} />
                  </div>
                </div>
                <div className='col-3'>
                  <div className="form-group">
                    <label htmlFor="digitocc" className='control-label'><strong>Digito:</strong></label>
                    <input type="text" className="form-control rounded mr-3" id="digitocc" name="digitocc"
                      placeholder="Digito da conta corrente" onChange={(handleChange)} />
                  </div>
                </div>
                <div className='col-6'>
                  <div className="form-group">
                    <label htmlFor="bank_account_name" className='control-label'><strong>Nome da Conta Bancaria:</strong></label>
                    <input type="text" className="form-control rounded mr-3" id="bank_account_name" name="bank_account_name"
                      placeholder="Nome da conta bancaria" onChange={(handleChange)} />
                  </div>
                </div>
              </div>
              
              <div className='row'>
                <div className='col-12'>
                  <p className='pt-4 h4'><strong>ENDEREÇO:</strong></p>
                </div>
              </div>
              <div className='row d-flex justify-content-center py-2'>
                <div className='col-6'>
                  <div className="form-group required">
                    <label htmlFor="zipCode" className='control-label'><strong>CEP:</strong></label>
                    <input type="text" className="form-control rounded mr-3" id="zipCode" name="zipCode"
                      placeholder="CEP" maxLength={9} onBlur={checkCEP} onChange={(handleChange)} />
                  </div>
                </div>
                <div className='col-6'>
                  <div className="form-group required">
                    <label htmlFor="neighborhood" className='control-label'><strong>Bairro:</strong></label>
                    <input type="text" className="form-control rounded mr-3" id="neighborhood" name="neighborhood"
                      placeholder="Bairro" value={data.neighborhood} onChange={(handleChange)} />
                  </div>
                </div>
              </div>
              <div className='row d-flex justify-content-center py-2'>
                <div className='col-6'>
                  <div className="form-group required">
                    <label htmlFor="Cidade" className='control-label'><strong>Cidade:</strong></label>
                    <input type="text" className="form-control rounded mr-3" id="city" name="city"
                      placeholder="Cidade" value={data.city} onChange={(handleChange)} />
                  </div>
                </div>
                <div className='col-6'>
                  <div className="form-group required">
                    <label htmlFor="uf" className='control-label'><strong>UF:</strong></label>
                    <input type="text" className="form-control rounded mr-3" id="uf" name="uf"
                      placeholder="UF" maxLength={2} value={data.uf} onChange={(handleChange)} />
                  </div>
                </div>
              </div>
              <div className='row d-flex justify-content-center py-2'>
                <div className='col-6'>
                  <div className="form-group required">
                    <label htmlFor="street" className='control-label'><strong>Rua:</strong></label>
                    <input type="text" className="form-control rounded mr-3" id="street" name="street"
                      placeholder="Rua" value={data.street} onChange={(handleChange)} />
                  </div>
                </div>
                <div className='col-6'>
                  <div className="form-group required">
                    <label htmlFor="number" className='control-label'><strong>Número:</strong></label>
                    <input type="number" className="form-control rounded mr-3" id="number" name="number"
                      placeholder="Número" onChange={(handleChange)} />
                  </div>
                </div>
              </div>
              <div className='row d-flex justify-content-center py-2'>
                <div className='col-6'>
                  <div className="form-group required">
                    <label htmlFor="contactNumber" className='control-label'><strong>Telefone:</strong></label>
                    <input type="text" className="form-control rounded mr-3" id="contactNumber" name="contactNumber"
                      placeholder="()XXXXX-XXXX" onChange={(handleChange)} />
                  </div>
                </div>
                <div className='col-6'>
                  <div className="form-group required">
                  </div>
                </div>
              </div>

              <div className='row my-2'>
                <div className='col-md-12'>
                  <div className="form-check">
                    <input className="form-check-input" type="checkbox" value={term} id="terms" onChange={() => setTerm(!term)} />
                    <label className="form-check-label" htmlFor="terms">
                      Concordo com os <a href='/login'>termos e condições de uso </a>
                    </label>
                  </div>
                </div>
              </div>
              <div className='row pt-3'>
                <div className='col-md-6'>
                  <a className='btn btn-danger' href='/login'>Voltar</a>
                </div>
                <div className='col-md-6 d-flex justify-content-end'>
                  <button className='btn btn-green' type='submit'>Cadastrar-se</button>
                </div>
              </div>
            </form>
          </div>
          <div className='offset-1'>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignUpProvider;